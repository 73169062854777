import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import useStyles from "./styles";
import moment from 'moment'

export default function DetailTable(props) {
  const classes = useStyles(props);
  const [showFullPanel, setshowFullPanel] = useState(false);
  const showMore = () => {
    setshowFullPanel(!showFullPanel);
  };
  const [deviceData, setdeviceData] = useState([])

  useEffect(() => {
    if (props.data) {
      var data = []
      props.data.sessionData.map((item, index) => {
        var paramavg1 = '-'
        var paramavg2 = '-'
        var parammax1 = '-'
        var parammax2 = '-'
        var txnCounter = '-'
        var rssi = ''
        Object.entries(item.data).forEach(([key, value]) => {
          if (key.includes('paramavg1')) {
            paramavg1 = value
          }
          else if (key.includes('paramavg2')) {
            paramavg2 = value
          }
          else if (key.includes('parammax1')) {
            parammax1 = value
          }
          else if (key.includes('parammax2')) {
            parammax2 = value
          }
          else if (key.includes('txn')) {
            txnCounter = value
          }
          else if (key.includes('rssi')) {
            rssi = value
          }
        })
        if ((paramavg1 !== '-') || (paramavg2 !== '-') || (parammax1 !== '-') || (parammax2 !== '-')) {
          data.push({
            time: moment(item.completed_at).format('DD-MMM hh:mm a'),
            avg1: paramavg1,
            avg2: paramavg2,
            max1: parammax1,
            max2: parammax2,
            txn: txnCounter,
            rssi: rssi
          })
        }
      })
      setdeviceData(data)

    }
  }, [props.data]);


  return (
    <div style={{ maxWidth: "100%" }} className="btmtable">
      <MaterialTable
        title='Data List'
        columns={[
          { title: "Date/Time", field: "time" },
          {
            title: 'Avg CR (in um/y)', field: 'avg1',
          },
          {
            title: 'Avg CR (in mpy)', field: 'avg2',
          },
          {
            title: 'Max CR (in um/y)', field: 'max1',
          },
          {
            title: 'Max CR (in mpy)', field: 'max2',
          },
        ]}
        data={deviceData}
        options={{
          grouping: false,
          search: false,
          toolbar: false,
          paging: false,
          exportAllData: true,
          exportButton: true,
          headerStyle: {
            fontSize: "12px",
            fontFamily: "Karla",
            color: "rgb(7, 71, 166)",
            backgroundColor: 'rgb(243 243 243)',
            padding: "4px 12px",
          },
          cellStyle: {
            fontSize: "12px",
            fontFamily: "Karla",
            fontWeight: "bold",
            padding: "4px 12px",
          },
          rowStyle: {
            height: "auto",
          },
          maxBodyHeight: 200,
          pageSize: 20,
        }}
      />
    </div>
  );
}

