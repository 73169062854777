import tinycolor from "tinycolor2";

const primary = "rgb(7, 71, 166)";
const secondary = "rgb(7, 71, 166)";
const warning = "#FFC260";
const success = "#3CD4A0";
const info = "#9013FE";
const red = "#ff274b";
const green = "#009d00";
const grey = "#808080";
const active = "#00CCCC";
const alert = "#F43530";
const inactive = "#BAE7FF";
const admin ="#00FFCC";
const limitedAdmin = "#FFCC66";
const readOnly = "#9699FF";
// const limitedAdmin = "#40A9FF";
// const readOnly = "#096DD9"
const usedSlots = "#69C0FF";
const availSlots = "#E6F7FF";
const lightenRate = 7.5;
const darkenRate = 15;
const white = "#FFF";
export default {
  palette: {
    white: {
      main: white,
      light: tinycolor(white)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(white)
        .darken(darkenRate)
        .toHexString(),
    },
    primary: {
      main: primary,
      light: tinycolor(primary)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(primary)
        .darken(darkenRate)
        .toHexString(),
    },
    secondary: {
      main: secondary,
      light: tinycolor(secondary)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(secondary)
        .darken(darkenRate)
        .toHexString(),
      contrastText: "#FFFFFF",
    },
    warning: {
      main: warning,
      light: tinycolor(warning)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(warning)
        .darken(darkenRate)
        .toHexString(),
    },
    success: {
      main: success,
      light: tinycolor(success)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(success)
        .darken(darkenRate)
        .toHexString(),
    },
    info: {
      main: info,
      light: tinycolor(info)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(info)
        .darken(darkenRate)
        .toHexString(),
    },
    text: {
      primary: "#4A4A4A",
      secondary: "#595959",
      hint: "#B9B9B9",
      white : "#FFF"
    },
    background: {
      default: "#F6F7FF",
      light: "transparent",
    },
    active: {
      main: active,
      light: tinycolor(active)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(active)
        .darken(darkenRate)
        .toHexString(),
    },
    admin: {
      main: admin,
      light: tinycolor(admin)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(admin)
        .darken(darkenRate)
        .toHexString(),
    },
    limitedAdmin: {
      main: limitedAdmin,
      light: tinycolor(limitedAdmin)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(limitedAdmin)
        .darken(darkenRate)
        .toHexString(),
    },
    readOnly: {
      main: readOnly,
      light: tinycolor(readOnly)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(readOnly)
        .darken(darkenRate)
        .toHexString(),
    },
    alert: {
      main: alert,
      light: tinycolor(alert)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(alert)
        .darken(darkenRate)
        .toHexString(),
    },
    inactive: {
      main: inactive,
      light: tinycolor(inactive)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(inactive)
        .darken(darkenRate)
        .toHexString(),
    },
    usedSlots: {
      main: usedSlots,
      light: tinycolor(usedSlots)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(usedSlots)
        .darken(darkenRate)
        .toHexString(),
    },
    availSlots: {
      main: availSlots,
      light: tinycolor(availSlots)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(availSlots)
        .darken(darkenRate)
        .toHexString(),
    },
    green: {
      main: green,
      light: tinycolor(green)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(green)
        .darken(darkenRate)
        .toHexString(),
    },
    red: {
      main: red,
      light: tinycolor(red)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(red)
        .darken(darkenRate)
        .toHexString(),
    },
    grey: {
      main: grey,
      light: tinycolor(grey)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(grey)
        .darken(darkenRate)
        .toHexString(),
    },
  },
  customShadows: {
    widget:
      "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetDark:
      "0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetWide:
      "0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: "#4A4A4A1A",
      },
    },
    MuiMenu: {
      paper: {
        boxShadow:
          "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
      },
    },
    MuiSelect: {
      icon: {
        color: "#B9B9B9",
      },
    },
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: "transparent !important",
          "&:focus": {
            backgroundColor: "rgb(7, 71, 166)",
          },
        },
      },
      button: {
        "&:hover, &:focus": {
          //backgroundColor: "rgb(7, 71, 166)",
        },
      },
    },
    MuiTouchRipple: {
      child: {
        backgroundColor: "white",
      },
    },
    MuiTableRow: {
      root: {
        height: 56,
      },
    },
    MuiTypography: {
      body: {
        fontFamily: "'Karla', sans-serif"
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: "1px solid rgba(224, 224, 224, .5)",
      },
      head: {
        fontSize: "0.95rem",
        fontFamily: "'Karla', sans-serif"
      },
      body: {
        fontSize: "0.95rem",
        fontFamily: "'Karla', sans-serif"
      },
    },
  },
};
