import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
    root: {
        display: "flex",
        backgroundColor: "rgba(196, 215, 215, 0.2)",
        borderBottom: "solid 1px #97B2B2",
        alignItems: "center",
        borderRadius: "8px 8px 0px 0px",
        padding: "8px 12px"
    },
    label: {
        flexGrow:1,
        overflow: "hidden"
    },
    labelText: {
        fontSize: "12px",
        fontFamily: "Karla",
        color: "#426262"
    },
    value:{
        fontSize: "15px",
        fontFamily: "Karla",
        color: "#213131",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    list: {
        width: '100%',
        minWidth: 'auto',
        backgroundColor: theme.palette.background.paper,
        paddingRight: "20px"
    },
    litxt: {
        color: '#2C7873',
        fontFamily: "Karla",
    },
    applyBtn: {
        borderRadius: '18px',
        fontSize: '14px',
        zIndex: 2,
        color: 'rgb(7, 71, 166)',
        fontFamily: 'Karla',
        fontWeight: 'bold',
        backgroundColor: 'rgb(243 243 243)',
        marginRight: '5%',
        marginBottom: '5%',
        marginTop: '-10%',
        float: 'right'
    }
}));