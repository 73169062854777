import React from "react";
import axios from "axios";
import moment from 'moment'

const AllSessionsContext = React.createContext();
const rootReducer = (state, action) => {
    switch (action.type) {
        case "UPDATE_DEVICES":
            return {
                isLoaded: true,
                devices: action.payload
            };
        case "GET_DEVICES":
            return {
                ...state,
                isLoaded: true,
                devices: action.payload,
                error: ''
            };
        case "GET_DEVICES_ERROR":
            return {
                ...state,
                isLoaded: true,
                devices: null,
                error: action.payload
            };
        case "GET_GPS_DATA":
            return {
                ...state,
                gpsData: action.payload,
                error: ''
            };
        case "GET_STREAM_DATA":
            return {
                ...state,
                isLoaded: true,
                streamData: action.payload,
                streamDataerror: ''
            };
        case "GET_STREAM_DATA_ERROR":
            return {
                ...state,
                isLoaded: true,
                streamData: null,
                streamDataerror: action.payload
            };
    }
}
const AllSessionsProvider = ({ children }) => {
    const [data, setData] = React.useReducer(rootReducer, {
        isLoaded: false,
        devices: null,
        gpsData: null,
        streamData: null,
        error: ''
    });
    return (
        <AllSessionsContext.Provider value={{ data, setData }}>
            {children}
        </AllSessionsContext.Provider>
    );
};

const useAllSessionsState = () => {
    const context = React.useContext(AllSessionsContext);
    return context;
};
export function getDevices(dispatch) {
    return axios.get(`/${localStorage.getItem("organization")}/devices`).then(res => {
        dispatch({ type: "GET_DEVICES", payload: res.data });
    }).catch(err => {
        dispatch({ type: "GET_DEVICES_ERROR", payload: err });
    });
}
export function getDeviceSession(dispatch, dateRange) {

    var start = moment(dateRange[0]).format('YYYY-MM-DD')
    var end = moment(dateRange[1]).format('YYYY-MM-DD')

    var startTime = start + ' 00:00'
    var endTime = end + ' 23:59'

    var utcstarttime = moment(startTime).utc().format('YYYY-MM-DD HH:mm')
    var utcendtime = moment(endTime).utc().format('YYYY-MM-DD HH:mm')

    return axios.get(`/${localStorage.getItem("organization")}/stream-session?alarm__last_triggered_at__range=${utcstarttime},${utcendtime}`).then(res => {
        let devicedata = res.data.results;
        if (res.data && res.data.results) {
            let reqArr = [];
            for (let i = 0; i < res.data.results.length; i++) {
                reqArr.push(axios.get(`/${localStorage.getItem("organization")}/stream-session-data/${res.data.results[i].id}`))
            }
            axios.all(reqArr).then(axios.spread((...responses) => {
                for (let r = 0; r < responses.length; r++) {
                    let res = responses[r];
                    for (let f = 0; f < devicedata.length; f++) {
                        if (res.data) {
                            let device = devicedata[r];
                            device.sessionData = res.data.results;
                        }
                    }
                }
                dispatch({ type: "GET_STREAM_DATA", payload: devicedata });
            }))
        }
    })
}

export function getGPSData(dispatch) {
    return axios.get(`/${localStorage.getItem("organization")}/devices`).then(res => {
        let devicedata = res.data.results;
        if (devicedata) {
            let reqArr = [];
            for (let i = 0; i < devicedata.length; i++) {
                reqArr.push(axios.get(`/${localStorage.getItem("organization")}/feeds?device__device_id=${devicedata[i].device_id}`))
            }
            axios.all(reqArr).then(axios.spread((...responses) => {
                let gpsdevices = []
                for (let r = 0; r < responses.length; r++) {
                    let res = responses[r];
                    for (let f = 0; f < res.data.results.length; f++) {
                        if (res.data.results[f].category === "gps" && devicedata[r].feed_status && res.data.results[f].feed_data.length > 0) {
                            //action.payload.data.results[f].feed_data[0].value
                            let device = devicedata[r];
                            device.devicelocation = JSON.parse(res.data.results[f].feed_data[0].value);
                            gpsdevices.push(device);
                        }
                    }
                }
                dispatch({ type: "GET_GPS_DATA", payload: gpsdevices });
            }))
        }


    }).catch(err => {
        //console.log(err);
    });
}

export { AllSessionsProvider, AllSessionsContext, useAllSessionsState };