import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
    sidebarStyle : {
        display: "inline-block",
        position: "absolute",
        top: 0,
        left: 0,
        margin: "12px",
        backgroundColor: "#404040",
        color: "#ffffff",
        zIndex: 1,
        padding: "6px",
        fontWeight: "bold"
    },         
    mapContainer: {
        position: "relative",
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        width: '100vw',
        height: '100vh'
    },
    morebtn: {
        borderRadius: '18px',
        fontSize: '14px',
        zIndex : 2,
        //color: '#2C7873',
        color : 'rgb(7, 71, 166)',
        fontFamily: 'Karla',
        fontWeight: 'bold',
        backgroundColor:'rgb(243 243 243)',
        //backgroundColor: '#F1FCFC',
        marginTop: '-20px',
        position: 'fixed',
        marginLeft: '-51px',

    },
    dateFilterDiv:{
        display: 'flex',
        marginTop: '-7%',
        float:'right',
        marginRight:'5%'
    },
    dateFilterChip: {
        display: 'inline-block',
        fontSize: '12px',
        fontFamily: 'Karla',
        fontWeight: 'bold',
        color: '#698D8D',
        //backgroundColor: '#F1FCFC',
        //borderRadius: '21px',
        marginLeft: '5px',
        width:'150px',
        marginTop:'-2%',
        cursor:'pointer'
    },
    modifycolbtn: {
        borderRadius: '18px',
        fontSize: '14px',
        color: '#2C7873',
        fontFamily: 'Karla',
        fontWeight: 'bold',
        backgroundColor: '#F1FCFC',
        marginTop: '-20px'
    },
    mapLoader: {
        justifyContent: "center",
        marginTop: "15%",
        textAlign:"center"
    },
    Fwversion: {
        flexGrow: 1,
        fontSize: '14px',
        fontFamily: 'Rubik',
        fontWeight : 'bold',
        color: 'rgb(7, 71, 166)',
        marginTop:'-5px'
    },
    deviceStatusActive: {
        flexGrow: 1,
        marginLeft : '15%',
        fontSize: '14px',
        fontFamily: 'Rubik',
        fontWeight : 'bold',
        color: 'rgb(7, 71, 166)',
        marginTop:'-5px',
        width : '100%'
    },
    deviceStatusInactive: {
        flexGrow: 1,
        marginLeft : '20%',
        fontSize: '14px',
        fontFamily: 'Rubik',
        fontWeight : 'bold',
        color: '#426262',
        marginTop:'-5px'
    },
    bottompanel:{
        position: "fixed",
        width: "85%",
        zIndex : 2,
        backgroundColor: "#FFFFFF",
        bottom: 0,
        left: "calc(3% + 48px)",
        height: "24%",
        borderRadius: "24px 24px 0 0",
        transition: theme.transitions.create(["height"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
    },
    fullpanel: {
        height: "calc(100vh - 200px)",
        overflow: 'auto'
    },
    filter: {
        flexGrow: 1, 
        flexBasis: 0, 
        padding: "12px",
        minWidth: 0
    },
    row: {
        display: 'flex'
    },
    rowlabel: {
        flexGrow: 1
    },
    tile: {
        flexGrow: 1, flexBasis: 0, margin: "12px", marginTop:'2%' 
    },
    locationName: {
        flexGrow: 1,
        fontSize: '20px',
        fontFamily: 'Rubik',
        color: '#426262',
        marginTop: "1%"
    },
    backbtn: {
        borderRadius: '18px',
        fontSize: '14px',
        color: '#2C7873',
        fontFamily: 'Karla',
        fontWeight: 'bold',
        backgroundColor: '#F1FCFC',
        position: 'relative',
        marginRight:'12px',
        padding: '3px 12px'
    },
    refreshbtn: {
        borderRadius: '18px',
        fontSize: '14px',
        color: 'rgb(7, 71, 166)',
        fontFamily: 'Karla',
        fontWeight: 'bold',
        backgroundColor: '#FFFFFF',
        position: 'relative',
        marginRight:'12px',
        padding: '3px 12px',
    },
    paper: {
        color: "rgb(7, 71, 166)",
      },
      paperHeader: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "17px",
        color: "rgb(7, 71, 166)",
        padding: "15px 2px 5px 15px",
      },
    deviceDetails: {
        //flexGrow: 1,
        fontSize: '15px',
        fontFamily: 'Rubik',
        fontWeight : 'bold',
        color: 'rgb(7, 71, 166)',
        marginTop:'18px',
        display:'flex'
    },
    TimeLabel: {
        flexGrow: 1,
        fontSize: '14px',
        fontFamily: 'Rubik',
        fontWeight : 'bold',
        color: '#426262',
        marginTop:'-5px',
        marginLeft:'2%'
    },
    filterChip: {
        display: 'inline-block',
        fontSize: '12px',
        fontFamily: 'Karla',
        fontWeight: 'bold',
        color: '#698D8D',
        padding: '8px 16px',
        backgroundColor: '#F1FCFC',
        border: '1px solid #C4D7D7',
        borderRadius: '21px',
        marginLeft: '16px'
    },
    topPanel:{
        position: "fixed",
        width: "80%",
        top: "64px",
        left: "calc(10% + 48px)",
        height: "auto",
        // borderRadius: "24px 24px 0 0",
        transition: theme.transitions.create(["height"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        //   display: 'flex',
        //   paddingTop: '10px'
    },
    layerbtn: {
        // borderRadius: '18px',
        // fontSize: '14px',
        // color: '#2C7873',
        // fontFamily: 'Karla',
        // fontWeight: 'bold',
        // backgroundColor: '#F1FCFC',
        position: 'fixed',
        top: '74px',
        right: 'calc(10% + 142px)'
    },
    hidebtn: {
        borderRadius: '18px',
        fontSize: '14px',
        color: '#2C7873',
        fontFamily: 'Karla',
        fontWeight: 'bold',
        backgroundColor: '#F1FCFC',
        position: 'fixed',
        top: '74px',
        right: 'calc(10% - 48px)'
    },
    detailbottompanel: {
        height: '190px'
    },
    detailfullpanel: {
        height: "calc(100vh - 120px)",
        overflow: 'auto'
    },
    tooltip: {
        pointerEvents: 'none',
        position: 'absolute',
        minWidth: '266px',
        height: 'auto', //'245px'
        background: 'rgba(33, 49, 49, 0.8)',
        borderRadius: '8px',
        padding: '16px',
        zIndex: 9,
        color: '#fff',
    },
    timecontrol: {
        marginTop: '50px', //'0px',
        transition: theme.transitions.create(["margin-top"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          position:'absolute',
          right: 0
    },
    hideControls: {
        marginTop: '-191px'
    },
    hiddenbottompanel: {
        height: '75px'
    },
}));