import * as React from 'react';
import {PureComponent} from 'react';
import {Marker} from 'react-map-gl';
import moment from 'moment'
const ICON = `M256,0C167.641,0,96,71.625,96,160c0,24.75,5.625,48.219,15.672,69.125C112.234,230.313,256,512,256,512l142.594-279.375
C409.719,210.844,416,186.156,416,160C416,71.625,344.375,0,256,0z M256,256c-53.016,0-96-43-96-96s42.984-96,96-96
c53,0,96,43,96,96S309,256,256,256z`;
const SIZE = 20;
export default class Pins extends PureComponent {
  render() {
    const {data, onClick, onHover, onRemove} = this.props;
    return data.map((point, index) => (
      <div>
      { point && point.longitude && point.latitude && <Marker key={`marker-${index}`} longitude={point.longitude} latitude={point.latitude}>
        {point.is_active ?
          <svg
            height={SIZE}
            viewBox="0 0 500 500"
            style={{
              cursor: 'pointer',
              fill: 'rgb(94, 223, 255)',
              stroke: 'none',
              transform: `translate(${-SIZE / 2}px,${-SIZE}px)`
            }}
            onClick={() => onClick(point)}
            onMouseEnter={() => onHover(point)}
            onMouseLeave={() => onRemove(point)}
          >
            <path d={ICON} />
          </svg> :
          <svg
            height={SIZE}
            viewBox="0 0 500 500"
            style={{
              cursor: 'pointer',
              fill: '#696969',
              stroke: 'none',
              transform: `translate(${-SIZE / 2}px,${-SIZE}px)`
            }}
            onClick={() => onClick(point)}
            onMouseEnter={() => onHover(point)}
            onMouseLeave={() => onRemove(point)}
          >
            <path d={ICON} />
          </svg>}
      </Marker>}</div>
    ));
  }
}

