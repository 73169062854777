import React, { memo, useEffect } from 'react'
import Popover from '@material-ui/core/Popover';
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp"
import { StaticDateRangePicker, DateRange, DateRangeDelimiter } from "@material-ui/pickers";
import { LocalizationProvider } from '@material-ui/pickers';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import useStyles from "./styles";
import moment from 'moment'
import { useHistory, useLocation } from "react-router-dom";
import { Button, TextField } from '@material-ui/core';

function DateRangeFilter(props) {
    const [selectedDate, handleDateChange] = React.useState(null);
    const [selectedLabel, setSelectedLabel] = React.useState('');
    const [fromPage, setfromPage] = React.useState(null);
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const location = useLocation();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        let selectedlabel = moment(selectedDate[0]).format("MMM D") + ' - ' + moment(selectedDate[1]).format("MMM D")
        setSelectedLabel(selectedlabel);
        props.onDateChange(selectedDate);
    };
    const open = Boolean(anchorEl);

    const handleDateRangeSelected = (range) => {
        handleDateChange(range);
    }
    useEffect(() => {
        if (props) {
            if(location && location.fromPage && location.fromPage == 'detail_popup'){
                handleDateChange([moment(location.retainedDate.selectedDate[0]).toDate(), moment(location.retainedDate.selectedDate[1]).toDate()])
            }
            else{
                handleDateChange([moment().subtract(7, 'days').toDate(), moment().toDate()])
            }
        }
    }, []);

    useEffect(() => {
        if(location && location.fromPage && location.fromPage == 'detail_popup'){
            let selectedlabel1 = moment(location.retainedDate.selectedDate[0]).format("MMM D") + ' - ' + moment(location.retainedDate.selectedDate[1]).format("MMM D")
            if(selectedLabel !== selectedlabel1){
                setSelectedLabel(selectedlabel1);
                props.onDateChange(selectedDate);
            }
            // handleDateRangeSelected([moment(location.retainedDate.selectedDate[0]).toDate(), moment(location.retainedDate.selectedDate[1]).toDate()])
        }
        else{
            if (selectedDate != null && !Boolean(anchorEl)) {
                let selectedlabel = moment(selectedDate[0]).format("MMM D") + ' - ' + moment(selectedDate[1]).format("MMM D")
                setSelectedLabel(selectedlabel);
                props.onDateChange(selectedDate);
            }
        }
    }, [selectedDate]);

    const id = open ? 'simple-popover' : undefined;
    return (
        <LocalizationProvider dateAdapter={DateFnsUtils}>
            <div className={props.className}>
                <div aria-describedby={id} onClick={handleClick} className={classes.root}>
                    <div className={classes.label}>
                        <div className={classes.labelText}>{props.label}</div>
                        <div className={classes.value}>{selectedLabel}</div>
                    </div>
                    {(!open) ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                </div>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    // onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <div style={{ display: "flex" }}>
                        <div style={{ borderLeft: "solid 1px #97B2B2" }}>
                            {
                                <StaticDateRangePicker
                                    displayStaticWrapperAs="static"
                                    value={selectedDate}
                                    onChange={date => handleDateRangeSelected(date)}
                                    disableFuture={true}
                                    renderInput={(startProps, endProps) => (
                                        <>
                                            <TextField {...startProps} />
                                            <DateRangeDelimiter> to </DateRangeDelimiter>
                                            <TextField {...endProps} />
                                        </>
                                    )}
                                />
                            }
                            <div>
                                <Button
                                    variant="contained"
                                    color="default"
                                    className={classes.applyBtn}
                                    onClick={handleClose}
                                >
                                    APPLY
                                </Button>
                            </div>
                        </div>
                        
                    </div>

                </Popover>
            </div>
        </LocalizationProvider>
    )
}

export default memo(DateRangeFilter)
