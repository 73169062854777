import * as React from 'react';
import { Component } from 'react';
import { render } from 'react-dom';
import MapGL, {
  Popup,
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl, WebMercatorViewport
} from 'react-map-gl';
import Pins from './pin';
import MarkerInfo from './marker-info';
import DetailPopup from './DetailPopup';

const TOKEN = 'pk.eyJ1IjoiYW5hYmF1YiIsImEiOiJjazgxNndyY2MwM2puM21wdDZldjRqem44In0.qhIfrQkyF5JfLPM-T9Gr5w'; // Set your mapbox token here

const geolocateStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  padding: '10px'
};

const fullscreenControlStyle = {
  position: 'absolute',
  top: 36,
  left: 0,
  padding: '10px'
};

const navStyle = {
  position: 'absolute',
  top: 72,
  left: 0,
  padding: '10px'
};

const scaleControlStyle = {
  position: 'absolute',
  bottom: 36,
  left: 0,
  padding: '10px'
};
const applyToArray = (func, array) => func.apply(Math, array)

const getBoundsForPoints = (cornersLongLat) => {
  const viewport = new WebMercatorViewport({ width: window.innerWidth - 125, height: 225 })
    .fitBounds(cornersLongLat, { paddingLeft: (64 + 20 + 450) }) // Can also use option: offset: [0, -100]
  const { longitude, latitude, zoom } = viewport
  return { longitude, latitude, zoom }
}

export default class DeviceMaps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewport: {
        latitude: 1.2887,
        longitude: 103.7911,
        bearing: 0,
        pitch: 0
      },
      data: props.data,
      detailData: props.detailData,
      detailInfo: null,
      popupInfo: null,
      hoverInfo: null
    };
  }

  componentDidMount() {
    if (this.state.data) {
      this.setViewPort();
    }
  }
  setViewPort() {
    let { data } = this.props;
    let pointsLong = [];
    let pointsLat = [];

    if (data && data.length > 0) {

      data.map((point, index) => {
        if (point.geometry && point.geometry.length > 0 && point.geometry[0] && point.geometry[1]) {
          pointsLong.push(point.geometry[0])
          pointsLat.push(point.geometry[1])
        }
      })
      let cornersLongLat = [
        [applyToArray(Math.min, pointsLong), applyToArray(Math.min, pointsLat)],
        [applyToArray(Math.max, pointsLong), applyToArray(Math.max, pointsLat)]
      ]
      const bounds = getBoundsForPoints(cornersLongLat)
      let currentViewState = { ...bounds, bearing: 0, pitch: 15, transitionDuration: 1000 };
      this.setState({ viewport: currentViewState });
    }
  }
  _updateViewport = viewport => {
    this.setState({ viewport });
  };

  _onClickMarker = (point, detail) => {
    this.setState({ popupInfo: point, detailInfo: detail });
  };

  _onHoverMarker = point => {
    this.setState({ hoverInfo: point });
  };

  _onRemoveMarker = point => {
    this.setState({ hoverInfo: null });
  };
  currentModalState = (state) => {
    this.setState({ popupInfo: null });
  };
  _renderPopup() {
    const { popupInfo, detailInfo } = this.state;
    return (
      popupInfo && (
        <DetailPopup action={'view'} currentModalState={(state) => this.currentModalState(state)} editMode={false} modal={true} data={popupInfo} detailData={detailInfo} />
      )
    );
  }
  _renderHoverPopup() {
    const { hoverInfo } = this.state;
    return (
      hoverInfo && (
        <MarkerInfo info={hoverInfo} />
      )
    );
  }
  render() {
    const { viewport, data, detailData } = this.state;

    return (
      <MapGL
        {...viewport}
        width="100%"
        height="100%"
        mapStyle="mapbox://styles/sla/cjnci1u153tqz2ro5eka7t0ef"
        onViewportChange={this._updateViewport}
        mapboxApiAccessToken={TOKEN}
      >
        <Pins data={data} detailData={detailData} onRemove={this._onRemoveMarker} onHover={this._onHoverMarker} onClick={this._onClickMarker} />

        {this.state.popupInfo && this._renderPopup()}
        {this._renderHoverPopup()}
        <div style={fullscreenControlStyle}>
          <FullscreenControl />
        </div>
        <div style={navStyle}>
          <NavigationControl />
        </div>
      </MapGL>
    );
  }
}
