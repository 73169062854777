import * as React from 'react';
import { PureComponent } from 'react';

const tooltip = {
  pointerEvents: 'none',
  position: 'absolute',
  minWidth: '250px',
  height: 'auto',
  background: 'rgba(33, 49, 49, 0.9)',
  borderRadius: '8px',
  padding: '8px',
  zIndex: 9,
  color: '#fff',
};
export default class MarkerInfo extends PureComponent {
  render() {
    const { info } = this.props;
    const time = `${info.time}`
    var feedlist = []
    if (info && info.feedlist) {
      feedlist = info.feedlist
    }
    return (
      <div style={tooltip} >
        <div>
          Time : {time}
        </div>
        <div>
          Avg CR (in um/y) : {info.avg1}
        </div>
        <div>
          Avg CR (in mpy) : {info.avg2} 
        </div>
        <div>
          Max CR (in um/y) : {info.max1}
        </div>
        <div>
          Max CR (in mpy) : {info.max2}
        </div>
        <div>
          Depth : {info.depth} m
        </div>
      </div>
    );
  }
}