import React, { memo, useEffect } from 'react'
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp"
import { StaticDatePicker } from "@material-ui/pickers";
import { LocalizationProvider } from '@material-ui/pickers';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import useStyles from "./styles";
import moment from 'moment'
import { TextField, Button } from '@material-ui/core';

const options = [
    {
        label: 'Today',
        action: () => {
            return [moment().toDate(), moment().toDate()];
        }
    },
    {
        label: 'Yesterday',
        action: () => {
            return [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()];
        }
    },
    {
        label: 'Custom range'
    }
];

function DateFilter(props) {

    let defaultOption = 0;
    const [selectedDate, handleDateChange] = React.useState(props.defaultDate);
    const [selectedLabel, setSelectedLabel] = React.useState(options[2].label);
    const classes = useStyles();
    const [checked, setChecked] = React.useState(2);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        let selectedlabel = options[checked].label;
        if (selectedlabel.indexOf("Custom") !== -1) {
            selectedlabel = moment(selectedDate[0]).format("MMM D"); // + ' - ' + moment(selectedDate[1]).format("MMM D")
        }
        setSelectedLabel(selectedlabel);
        props.onDateChange(selectedDate, options[checked].label);
    };
    const open = Boolean(anchorEl);

    const handleToggle = (value, index) => () => {
        if (checked !== index) {
            setChecked(index);
            if (options[index].label.indexOf("Custom") === -1) {
                let daterange = options[index].action();
                handleDateChange(daterange);
            }
        }
    };

    const handleDateRangeSelected = (range) => {
        var today = moment();
        var yesterday = moment().subtract(1, 'day');
        if (moment(range[0]).isSame(today, 'day')) {
            setChecked(0);
        } else if (moment(range[0]).isSame(yesterday, 'day')) {
            setChecked(1);
        } else {
            setChecked(options.length - 1);
            // console.log(options.length - 1, 'LENGTH')
        }
        handleDateChange(range);
    }

    useEffect(() => {
        let date = moment.unix(selectedDate).format('DD-MMM YYYY')
        // console.log(date, 'DATE selelcted')
        let dateRange = [date, date]
        let selectedlabel = options[checked].label;

        if (selectedlabel.indexOf("Custom") !== -1) {
            selectedlabel = moment(date).format("MMM D"); // + ' - ' + moment(selectedDate[1]).format("MMM D")
        }
        handleDateChange(dateRange);
        setSelectedLabel(selectedlabel);
    }, [checked]);

    useEffect(() => {
        if (selectedDate[0] != null && !Boolean(anchorEl)) {
            props.onDateChange(selectedDate, options[checked].label);
        }
    }, [selectedDate]);

    const id = open ? 'simple-popover' : undefined;
    return (
        <LocalizationProvider dateAdapter={DateFnsUtils}>
            <div className={props.className}>
                <div aria-describedby={id} onClick={handleClick} className={classes.root}>
                    <div className={classes.label}>
                        <div className={classes.labelText}>{props.label}</div>
                        <div className={classes.value}>{selectedLabel}</div>
                    </div>
                    {(!open) ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                </div>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    // onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <div style={{ display: "flex" }}>
                        <div style={{ display: 'none' }}>
                            <List className={classes.list}>
                                {options.map((item, index) => {
                                    const labelId = `checkbox-list-label-${index}`;
                                    return (
                                        <ListItem key={index} role={undefined} dense button onClick={handleToggle(item, index)}>
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={index === checked}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                    color="primary"
                                                />
                                            </ListItemIcon>
                                            <ListItemText id={labelId} primary={`${item.label}`} className={classes.litxt} />
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </div>
                        <div style={{ borderLeft: "solid 1px #97B2B2" }}>
                            {
                                <StaticDatePicker
                                    autoOk
                                    openTo="date"
                                    value={selectedDate[0]}
                                    renderInput={(startProps) => (
                                        <>
                                            <TextField {...startProps} />
                                        </>
                                    )}
                                    disableFuture={true}
                                    todayLabel="TODAY"
                                    onChange={date => handleDateRangeSelected([date, date])}
                                />
                            }
                        </div>
                    </div>
                    <div>
                        <Button
                            variant="contained"
                            color="default"
                            className={classes.applyBtn}
                            onClick={handleClose}
                        >
                            APPLY
                         </Button>
                    </div>
                </Popover>
            </div>
        </LocalizationProvider>
    )
}

export default memo(DateFilter)
