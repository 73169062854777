import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import { ButtonGroup } from "@material-ui/core";
import useStyles from "./styles";
import moment from 'moment'
import ReactEcharts from 'echarts-for-react';

export default function PreviewTable(props) {
  const classes = useStyles(props);
  const [showFullPanel, setshowFullPanel] = useState(false);
  const [showFeedGraph, setshowFeedGraph] = useState(false)
  const [chartOptions, setchartOptions] = useState(null);
  const showMore = () => {
    setshowFullPanel(!showFullPanel);
  };
  const [deviceData, setdeviceData] = useState([])

  useEffect(() => {
    if (props.data) {
      var data = []
      var feeds = []
      var avg = []
      var max = []
      props.data.map((item, index) => {
        var paramavg1 = '-'
        var paramavg2 = '-'
        var parammax1 = '-'
        var parammax2 = '-'
        var txnCounter = '-'
        var rssi = ''
        Object.entries(item.data).forEach(([key, value]) => {
          if (key.includes('paramavg1')) {
            paramavg1 = value
          }
          else if (key.includes('paramavg2')) {
            paramavg2 = value
          }
          else if (key.includes('parammax1')) {
            parammax1 = value
          }
          else if (key.includes('parammax2')) {
            parammax2 = value
          }
          else if (key.includes('txn')) {
            txnCounter = value
          }
          else if (key.includes('rssi')) {
            rssi = value
          }
        })
        if ((paramavg1 !== '-') || (paramavg2 !== '-') || (parammax1 !== '-') || (parammax2 !== '-')) {
          data.push({
            time: moment(item.completed_at).format('DD-MMM hh:mm a'),
            completed_at: item.completed_at,
            avg1: paramavg1,
            avg2: paramavg2,
            max1: parammax1,
            max2: parammax2,
            txn: txnCounter,
            rssi: rssi
          })
        }
      })
      setdeviceData(data)

      var feedNames = ['Avg CR (in um/y)', 'Avg CR (in mpy)', 'Max CR (in um/y)', 'Max CR (in mpy)']
      var avg1data = []
      var avg2data = []
      var max1data = []
      var max2data = []
      var feeddata = []
      data.forEach((item, index) => {
        avg1data.push({ name: 'Avg CR (in um/y)', completed_at: item.completed_at, value: item.avg1 })
        avg2data.push({ name: 'Avg CR (in mpy)', completed_at: item.completed_at, value: item.avg2 })
        max1data.push({ name: 'Max CR (in um/y)', completed_at: item.completed_at, value: item.max1 })
        max2data.push({ name: 'Max CR (in mpy)', completed_at: item.completed_at, value: item.max2 })
      })
      feeddata.push(avg1data)
      feeddata.push(avg2data)
      feeddata.push(max1data)
      feeddata.push(max2data)

      let colrArray = ['#5EDFFF', '#3E64FF', '#63C292', '#FFBA5A', '#c23531', '#2f4554', '#61a0a8', '#d48265', '#91c7ae', '#749f83', '#ca8622', '#bda29a', '#6e7074', '#546570', '#c4ccd3']

      let seriesData = feeddata.map((f, i) => {
        return {
          name: feedNames[i],
          type: 'line',
          smooth: true,
          // yAxisIndex: i,
          data: f.map(d => [d.completed_at, d.value]),
          itemStyle: {
            color: colrArray[i]
          },
          lineStyle: {
            color: colrArray[i]
          }
        }
      })
      let chOption = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'axis',
            crossStyle: {
              color: '#999'
            },
            label: {
              formatter: (val) => {
                return moment(val.value).format('DD-MMM hh:mm a')
              }
            }
          },
          formatter: (params) => {
            let title = `<div>${params[0].axisValueLabel}</div>`;
            let out = params.map((s, i) => {
              return `<div>${s.marker} ${s.seriesName} :  ${s.value[1]}</div>`
            })

            return title + out.join('');
          }
        },
        legend: {
          show: true,
          textStyle: {
            fontWeight: 'bold',
            fontSize: 12,
            fontFamily: 'Karla,sans-serif'
          },
        },
        grid: {
          left: 85,
        },
        toolbox: {
          feature: {
            saveAsImage: { show: true, title: 'Save as PNG' }
          }
        },
        xAxis: [
          {
            type: 'time',
            name: 'Duration',
            axisPointer: {
              type: 'shadow'
            },
            splitLine: {
              lineStyle: {
                type: 'dashed',
                opacity: 0.7
              }
            },
            axisLabel: {
              formatter: (val) => {
                return moment(val).format('DD-MMM hh:mm a')
              }
            },
            nameTextStyle: {
              fontWeight: 'bold',
              fontSize: 11,
              fontFamily: 'Karla,sans-serif'
            }
          }
        ],
        yAxis: [{
          type: 'value',
          nameTextStyle: {
            fontWeight: 'bold',
            fontSize: 11,
            fontFamily: 'Karla,sans-serif'
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              opacity: 0.7
            }
          }
        }],
        dataZoom: [
          {
            type: 'slider',
            xAxisIndex: 0,
            filterMode: 'empty'
          },
          {
            type: 'inside',
            xAxisIndex: 0,
            filterMode: 'empty'
          }
        ],
        series: seriesData,
      };
      setchartOptions(chOption);

    }
  }, [props.data]);


  return (
    <div className={classes.bottompanel + (!showFullPanel ? "" : " " + classes.fullpanel)} >
      <div style={{ textAlign: "center" }}>
        {!showFullPanel ? (
          <Button
            variant="contained"
            color="default"
            className={classes.morebtn}
            onClick={showMore}
          >
            SEE MORE <ExpandLessIcon></ExpandLessIcon>
          </Button>
        ) : (
            <Button
              variant="contained"
              color="default"
              className={classes.morebtn}
              onClick={showMore}
            >
              SEE LESS <ExpandMoreIcon></ExpandMoreIcon>
            </Button>
          )}
      </div>
      <div style={{ maxWidth: "100%" }} className="btmtable">

        <div style={{ marginTop: '2%', marginBottom: '2%', marginLeft: '2%' }} >
          <ButtonGroup color="primary" aria-label="contained primary button group" style={{ borderRadius: 20 }}>
            <Button variant={showFeedGraph ? 'contained' : 'outlined'} style={{ fontSize: 13, fontWeight: 'bold', borderTopLeftRadius: 20, borderBottomLeftRadius: 20 }} onClick={() => setshowFeedGraph(true)}>GRAPH</Button>
            <Button variant={!showFeedGraph ? 'contained' : 'outlined'} style={{ fontSize: 13, fontWeight: 'bold', borderTopRightRadius: 20, borderBottomRightRadius: 20 }} onClick={() => setshowFeedGraph(false)}>TABLE</Button>
          </ButtonGroup>
        </div>
        {(!showFeedGraph && deviceData) &&
          <MaterialTable
            title='Data List'
            columns={[
              { title: "Date/Time", field: "time" },
              {
                title: 'Avg CR (in um/y)', field: 'avg1',
              },
              {
                title: 'Avg CR (in mpy)', field: 'avg2',
              },
              {
                title: 'Max CR (in um/y)', field: 'max1',
              },
              {
                title: 'Max CR (in mpy)', field: 'max2',
              }
            ]}
            data={deviceData}
            options={{
              grouping: false,
              paging: false,
              exportAllData: true,
              exportButton: true,
              headerStyle: {
                fontSize: "12px",
                fontFamily: "Karla",
                color: "rgb(7, 71, 166)",
                backgroundColor: 'rgb(243 243 243)',
                padding: "4px 12px",
              },
              cellStyle: {
                fontSize: "12px",
                fontFamily: "Karla",
                fontWeight: "bold",
                padding: "4px 12px",
              },
              rowStyle: {
                height: "auto",
              },
              maxBodyHeight: 'calc(100vh - 375px)',
              pageSize: 20,
            }}
          />}
        {(chartOptions && showFeedGraph) &&
          <div style={{ border: '1px solid #DFE1E6', borderRadius: 7, height: '350px' }}>
            <ReactEcharts option={chartOptions} notMerge={true} style={{ height: '100%', width: '100%' }} />
          </div>
        }
      </div>
    </div>
  );
}

