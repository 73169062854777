import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import PageTitle from "../../components/PageTitle";
import { config } from '../../config';
import DeviceMaps from './DeviceMap'
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CropFreeOutlinedIcon from "@material-ui/icons/CropFreeOutlined";
import MaterialTable from "material-table";
import { useHistory, useLocation } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Refresh from "@material-ui/icons/Refresh";
import useStyles from "./styles";
import DetailPopup from "./DetailPopup";
import PreviewTable from "./PreviewTable";
import moment from 'moment'
import Paper from "@material-ui/core/Paper";
import DateFilter from "../../components/DateFilter";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDeviceState, getGPSData, getDeviceData, getDeviceSession, getDeviceSessionData, patchDeviceSessionData } from "../../context/DeviceContext";
import {
  SignalCellular1Bar, SignalCellular2Bar, SignalCellular3Bar, SignalCellular4Bar,
  Battery20, Battery30, Battery50, Battery60, Battery80, Battery90, BatteryFull, ArrowBack, Power
} from "@material-ui/icons";
import DetailTable from "./DetailTable";
const deviceSampleData = [
  { time: "25-Jun-2020 03:59 PM - 04:02 PM", param1: '25', param2: "60", param3: "70", param4: "20", battery: "74", depth: "0.3m", latitude: 1.288395, longitude: 103.791439 },
  { time: "25-Jun-2020 03:54 PM - 03:48 PM", param1: '30', param2: "65", param3: "75", param4: "25", battery: "75", depth: "0.3m", latitude: 1.288326, longitude: 103.791112 },
  { time: "25-Jun-2020 03:49 PM - 03:43 PM", param1: '35', param2: "70", param3: "80", param4: "30", battery: "75", depth: "0.3m", latitude: 1.288143, longitude: 103.790645 },
  { time: "25-Jun-2020 03:43 PM - 03:48 PM", param1: '40', param2: "75", param3: "85", param4: "35", battery: "75", depth: "0.3m", latitude: 1.288664, longitude: 103.789942 },
  { time: "25-Jun-2020 03:38 PM - 03:42 PM", param1: '45', param2: "80", param3: "90", param4: "40", battery: "75", depth: "0.3m", latitude: 1.289227, longitude: 103.790028 },

];

function DeviceDetails(props) {

  const classes = useStyles(props);
  const context = useDeviceState();
  const history = useHistory();
  const location = useLocation();
  const [showFullPanel, setshowFullPanel] = useState(false);
  const [showPopup, setshowPopup] = useState(false);
  const [popupData, setPopupData] = useState(null);
  const [selectedDate, setselectedDate] = useState(null);
  const [selectedDateType, setselectedDateType] = useState('');
  const [deviceData, setdeviceData] = useState([])
  const [streamData, setstreamData] = useState([])
  const [gpsData, setgpsData] = useState([])
  const [filteredgpsData, setfilteredgpsData] = useState([])
  const [streamDataDetail, setstreamDataDetail] = useState([])
  const [feedData, setfeedData] = useState([])
  const [isLoading, setisLoading] = useState(true)
  const [deviceBatteryVal, setdeviceBatteryVal] = useState(null);
  const [deviceRssiVal, setdeviceRssiVal] = useState(null);
  const [lastSeenTime, setlastSeenTime] = useState('');
  const [currentDate, setcurrentDate] = useState('');
  const [selectedDeviceId, setselectedDeviceId] = useState(null);
  // const [paramavg1, setparamavg1] = useState('')
  // const [paramavg2, setparamavg2] = useState('')
  // const [parammax1, setparammax1] = useState('')
  // const [parammax2, setparammax2] = useState('')
  const [tableColumns, settableColumns] = useState([])
  const [paramResults, setParamresults] = useState([])
  const [indexCount, setindexCount] = useState(0)
  const [formattedStartDate, setformattedStartDate] = useState(null);
  const [formattedEndDate, setformattedEndDate] = useState(null);
  const [openPopup, setOpenPopup] = React.useState(true);
  useEffect(() => {
    if (location) {
      var deviceID = location.pathname.split('/')
      setselectedDeviceId(deviceID[3])
    } 
    if(location && location.state && location.state.fromPage && location.state.fromPage == 'all_sessions'){
      setPopupData(location.state.detail)
    }
    getDeviceData(context.setData, deviceID[3]);
    //getGPSData(context.setData, context.setData, selectedDeviceId);
  }, [context.setData]);

  useEffect(() => {
    if (context.data.device) {
      setdeviceData(context.data.device)
    }
  }, [context.data.device]);

  useEffect(() => {
    if (context.data.streamDevices) {
      setstreamData(context.data.streamDevices)
      let gpsData = context.data.streamDevices.map((item, index) => {
        //getDeviceSessionData(context.setData, item.id, selectedDeviceId);
        if (item.notes && item.notes.avgValues) {
          //console.log('has notes')
          var paramavg1 = item.notes.avgValues.avg1;
          var paramavg2 = item.notes.avgValues.avg2;
          var parammax1 = item.notes.avgValues.max1;
          var parammax2 = item.notes.avgValues.max2;
          var depthValue = (item.notes.depth) ? item.notes.depth : 0.3
          if (item.gps && item.gps.coordinates && item.gps.coordinates[0] && item.gps.coordinates[1]) {
            return {
              avg1: (paramavg1 > 0.0) ? parseFloat(paramavg1).toFixed(6) : '-',
              avg2: (paramavg2 > 0.0) ? parseFloat(paramavg2).toFixed(6) : '-',
              max1: (parammax1 > 0.0) ? parseFloat(parammax1).toFixed(6) : '-',
              max2: (parammax2 > 0.0) ? parseFloat(parammax2).toFixed(6) : '-',
              notes: item.notes, depth: depthValue, cleared : item.cleared,
              completed_at: item.created_at, last_triggered_at: item.last_triggered_at,
              completed_at_formatted: moment(item.created_at).format('DD-MM-YYYY hh:mm a') + '-' + moment(item.last_triggered_at).format('DD-MM-YYYY hh:mm a'),
              sessionId: item.id, sessionData: item.sessionData, geometry: item.gps.coordinates, longitude: parseFloat(item.gps.coordinates[0]), latitude: parseFloat(item.gps.coordinates[1]), time: moment(item.created_at).format('DD-MMM hh:mm a')
            }
          }
          else {
            if (item.sessionData && item.sessionData.length > 0) {
              if (item.sessionData[0].gps && item.sessionData[0].gps.coordinates && item.sessionData[0].gps.coordinates[0] && item.sessionData[0].gps.coordinates[1]) {
                return {
                  avg1: (paramavg1 > 0.0) ? parseFloat(paramavg1).toFixed(6) : '-',
                  avg2: (paramavg2 > 0.0) ? parseFloat(paramavg2).toFixed(6) : '-',
                  max1: (parammax1 > 0.0) ? parseFloat(parammax1).toFixed(6) : '-',
                  max2: (parammax2 > 0.0) ? parseFloat(parammax2).toFixed(6) : '-',
                  notes: item.notes, depth: depthValue, cleared : item.cleared,
                  completed_at: item.created_at, last_triggered_at: item.last_triggered_at,
                  completed_at_formatted: moment(item.created_at).format('DD-MM-YYYY hh:mm a') + '-' + moment(item.last_triggered_at).format('DD-MM-YYYY hh:mm a'),
                  sessionId: item.id, sessionData: item.sessionData, geometry: item.sessionData[0].gps.coordinates, longitude: item.sessionData[0].gps.coordinates[0], latitude: item.sessionData[0].gps.coordinates[1], time: moment(item.created_at).format('DD-MMM hh:mm a')
                }
              }
              else {
                return {
                  avg1: (paramavg1 > 0.0) ? parseFloat(paramavg1).toFixed(6) : '-',
                  avg2: (paramavg2 > 0.0) ? parseFloat(paramavg2).toFixed(6) : '-',
                  max1: (parammax1 > 0.0) ? parseFloat(parammax1).toFixed(6) : '-',
                  max2: (parammax2 > 0.0) ? parseFloat(parammax2).toFixed(6) : '-',
                  notes: item.notes, depth: depthValue, cleared : item.cleared,
                  completed_at: item.created_at, last_triggered_at: item.last_triggered_at,
                  completed_at_formatted: moment(item.created_at).format('DD-MM-YYYY hh:mm a') + '-' + moment(item.last_triggered_at).format('DD-MM-YYYY hh:mm a'),
                  sessionId: item.id, sessionData: item.sessionData, geometry: [], longitude: null, latitude: null, time: moment(item.created_at).format('DD-MMM hh:mm a')
                }
              }
            }
            else {
              return {
                avg1: (paramavg1 > 0.0) ? parseFloat(paramavg1).toFixed(6) : '-',
                avg2: (paramavg2 > 0.0) ? parseFloat(paramavg2).toFixed(6) : '-',
                max1: (parammax1 > 0.0) ? parseFloat(parammax1).toFixed(6) : '-',
                max2: (parammax2 > 0.0) ? parseFloat(parammax2).toFixed(6) : '-',
                notes: item.notes, depth: depthValue, cleared : item.cleared,
                completed_at: item.created_at, last_triggered_at: item.last_triggered_at,
                completed_at_formatted: moment(item.created_at).format('DD-MM-YYYY hh:mm a') + '-' + moment(item.last_triggered_at).format('DD-MM-YYYY hh:mm a'),
                sessionId: item.id, sessionData: item.sessionData, geometry: [], longitude: null, latitude: null, time: moment(item.created_at).format('DD-MMM hh:mm a')
              }
            }
          }
        }
        else {
          //console.log('has no notes')
            var paramavg1 = 0;
            var paramavg2 = 0;
            var parammax1 = 0;
            var parammax2 = 0;
            var depthVal = 0.3;
            var paramavg1count = 0;
            var paramavg2count = 0;
            var parammax1count = 0;
            var parammax2count = 0;
            var count = 0;
            count = item.sessionData.length
            item.sessionData.map((data, index1) => {
              Object.entries(data.data).forEach(([key, value]) => {
                if (key.includes('paramavg1')) {
                  paramavg1 = (paramavg1 + value)
                  paramavg1count = paramavg1count + 1
                }
                else if (key.includes('paramavg2')) {
                  paramavg2 = (paramavg2 + value)
                  paramavg2count = paramavg2count + 1
                }
                else if (key.includes('parammax1')) {
                  parammax1 = (parammax1 + value)
                  parammax1count = parammax1count + 1
                }
                else if (key.includes('parammax2')) {
                  parammax2 = (parammax2 + value)
                  parammax2count = parammax2count + 1
                }
              })
            })
            paramavg1 = paramavg1 / paramavg1count
            paramavg2 = paramavg2 / paramavg2count
            parammax1 = parammax1 / parammax1count
            parammax2 = parammax2 / parammax2count
            if (item.gps && item.gps.coordinates && item.gps.coordinates[0] && item.gps.coordinates[1]) {
              return {
                avg1: (paramavg1 > 0.0) ? parseFloat(paramavg1).toFixed(6) : '-',
                avg2: (paramavg2 > 0.0) ? parseFloat(paramavg2).toFixed(6) : '-',
                max1: (parammax1 > 0.0) ? parseFloat(parammax1).toFixed(6) : '-',
                max2: (parammax2 > 0.0) ? parseFloat(parammax2).toFixed(6) : '-',
                notes: item.notes, depth: depthVal, cleared : item.cleared,
                completed_at: item.created_at, last_triggered_at: item.last_triggered_at,
                completed_at_formatted: moment(item.created_at).format('DD-MM-YYYY hh:mm a') + '-' + moment(item.last_triggered_at).format('DD-MM-YYYY hh:mm a'),
                sessionId: item.id, sessionData: item.sessionData, geometry: item.gps.coordinates, longitude: parseFloat(item.gps.coordinates[0]), latitude: parseFloat(item.gps.coordinates[1]), time: moment(item.created_at).format('DD-MMM hh:mm a')
              }
            }
            else {
              if (item.sessionData && item.sessionData.length > 0) {
                if (item.sessionData[0].gps && item.sessionData[0].gps.coordinates && item.sessionData[0].gps.coordinates[0] && item.sessionData[0].gps.coordinates[1]) {
                  return {
                    avg1: (paramavg1 > 0.0) ? parseFloat(paramavg1).toFixed(6) : '-',
                    avg2: (paramavg2 > 0.0) ? parseFloat(paramavg2).toFixed(6) : '-',
                    max1: (parammax1 > 0.0) ? parseFloat(parammax1).toFixed(6) : '-',
                    max2: (parammax2 > 0.0) ? parseFloat(parammax2).toFixed(6) : '-',
                    notes: item.notes, depth: depthVal, cleared : item.cleared,
                    completed_at: item.created_at, last_triggered_at: item.last_triggered_at,
                    completed_at_formatted: moment(item.created_at).format('DD-MM-YYYY hh:mm a') + '-' + moment(item.last_triggered_at).format('DD-MM-YYYY hh:mm a'),
                    sessionId: item.id, sessionData: item.sessionData, geometry: item.sessionData[0].gps.coordinates, longitude: item.sessionData[0].gps.coordinates[0], latitude: item.sessionData[0].gps.coordinates[1], time: moment(item.created_at).format('DD-MMM hh:mm a')
                  }
                }
                else {
                  return {
                    avg1: (paramavg1 > 0.0) ? parseFloat(paramavg1).toFixed(6) : '-',
                    avg2: (paramavg2 > 0.0) ? parseFloat(paramavg2).toFixed(6) : '-',
                    max1: (parammax1 > 0.0) ? parseFloat(parammax1).toFixed(6) : '-',
                    max2: (parammax2 > 0.0) ? parseFloat(parammax2).toFixed(6) : '-',
                    notes: item.notes, depth: depthVal, cleared : item.cleared,
                    completed_at: item.created_at, last_triggered_at: item.last_triggered_at,
                    completed_at_formatted: moment(item.created_at).format('DD-MM-YYYY hh:mm a') + '-' + moment(item.last_triggered_at).format('DD-MM-YYYY hh:mm a'),
                    sessionId: item.id, sessionData: item.sessionData, geometry: [], longitude: null, latitude: null, time: moment(item.created_at).format('DD-MMM hh:mm a')
                  }
                }
              }
              else {
                return {
                  avg1: (paramavg1 > 0.0) ? parseFloat(paramavg1).toFixed(6) : '-',
                  avg2: (paramavg2 > 0.0) ? parseFloat(paramavg2).toFixed(6) : '-',
                  max1: (parammax1 > 0.0) ? parseFloat(parammax1).toFixed(6) : '-',
                  max2: (parammax2 > 0.0) ? parseFloat(parammax2).toFixed(6) : '-',
                  notes: item.notes, depth: depthVal, cleared : item.cleared,
                  completed_at: item.created_at, last_triggered_at: item.last_triggered_at,
                  completed_at_formatted: moment(item.created_at).format('DD-MM-YYYY hh:mm a') + '-' + moment(item.last_triggered_at).format('DD-MM-YYYY hh:mm a'),
                  sessionId: item.id, sessionData: item.sessionData, geometry: [], longitude: null, latitude: null, time: moment(item.created_at).format('DD-MMM hh:mm a')
                }
              }
            }
        }
      });

      if(gpsData && gpsData.length > 0){
        var filteredgpsData = gpsData.filter(f => f !== undefined)
        filteredgpsData = gpsData.filter(f => (f && f.avg1 && f.avg1 !== '-') || (f && f.avg2 && f.avg2 !== '-') || (f && f.max1 &&  f.max1 !== '-') || (f && f.max2 && f.max2 !== '-'))
        setgpsData(filteredgpsData)
      }
      let tblColumns = [{
        title: 'Time', export: false, field: 'completed_at', render: rowData => moment(rowData.completed_at).format('DD-MMM hh:mm a') + '-' + moment(rowData.last_triggered_at).format('hh:mm a')
      },
      { title: "Time", export: true, hidden: true, field: "completed_at_formatted" },

      {
        title: 'Avg CR (in um/y)', field: 'avg1',
      },
      {
        title: 'Avg CR (in mpy)', field: 'avg2',
      },
      {
        title: 'Max CR (in um/y)', field: 'max1',
      },
      {
        title: 'Max CR (in mpy)', field: 'max2',
      },
      {
        title: 'Depth(in m)', field: 'depth',
      },
      {
        title: 'GPS Coordinates', field: 'geometry',
        render: rowData => (rowData.geometry && rowData.geometry.length > 0) ? 'Lat : ' + rowData.geometry[1] + ', Long : ' + rowData.geometry[0] : '-'
      }
      ];
      settableColumns(tblColumns);
      setisLoading(false)
    }
  }, [context.data.streamDevices]);

  useEffect(() => {
    if (gpsData) {
      patchDeviceSessionData(context.setData, gpsData, selectedDeviceId);
      var filteredItems = gpsData.filter(f => f.geometry && f.geometry.length > 0)
      setfilteredgpsData(filteredItems)
    }
  }, [gpsData])

  useEffect(() => {
    if (deviceData) {
      setdeviceRssiVal(deviceData.rssi)
      setdeviceBatteryVal(deviceData.battery_level)
      setlastSeenTime(deviceData.last_seen)
    }
  }, [deviceData]);

  useEffect(() => {
    if (context.setData && currentDate && selectedDeviceId) {
      var dateSelected = moment(selectedDate[0]).format('YYYY-MM-DD')
      var startTime = '00:00:00'
      var endTime = '23:59:00'
      var sDate = moment(dateSelected + ' ' + startTime)
      var eDate = moment(dateSelected + ' ' + endTime)
      setformattedStartDate(sDate);
      setformattedEndDate(eDate);
      setisLoading(true)
      if (sDate && eDate) {
        getDeviceSession(context.setData, selectedDeviceId, sDate, eDate)
      }
      // 
      //getGPSData(context.setData, context.setData, selectedDeviceId, dateSelected);
    }
    // eslint-disable-next-line
  }, [context.setData, currentDate, selectedDeviceId]);

  const onRefreshData = () => {
    setisLoading(true)
    getDeviceSession(context.setData, selectedDeviceId, formattedStartDate, formattedEndDate)
  };

  useEffect(() => {
    if (selectedDate) {
      var dateSelected = moment(selectedDate[0]).format('DD-MM-YYYY')
      setcurrentDate(dateSelected)
    }
  }, [selectedDate]);

  useEffect(() => {
    if (popupData) {
      setshowPopup(true)
    }
    else {
      setshowPopup(false)
    }
  }, [popupData]);
  const showMore = () => {
    setshowFullPanel(!showFullPanel);
  };
  const onBackPressed = () => {
    history.push({
      pathname: `/app/dashboard`,
    });
  };
  const onDateChange = (date1, label) => {
    let date = [moment.unix(lastSeenTime).format('DD-MMM-YYYY hh:mm a'), moment.unix(lastSeenTime).format('DD-MMM-YYYY hh:mm a')]
    setselectedDate(date1);
    setselectedDateType(label);
    setgpsData([])
    setfilteredgpsData([])
  }
  const currentModalState = (state) => {
    setPopupData(null)
    setshowPopup(state);
  };
  return (
    <>
      <div style={{ overflow: 'hidden' }}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <div>
            {<div style={{ borderBottom: "solid 1px #c4d7d7", background: 'white', padding: "12px 12px 8px 20px", alignItems: "center" }}>
              <div style={{ display: 'flex' }}>
                <Button
                  variant="contained"
                  color="default"
                  className={classes.refreshbtn}
                  onClick={onBackPressed}
                >
                  <ArrowBackIcon style={{ marginRight: "12px" }} /> BACK TO DEVICES
                    </Button>
                <Button
                  variant="contained"
                  color="default"
                  className={classes.refreshbtn}
                  onClick={onRefreshData}
                >
                  <Refresh style={{ marginRight: "12px" }}></Refresh> REFRESH
                    </Button>
              </div>
              <div className={classes.locationName}>Device - {deviceData && deviceData.alias_name} ({location && location.pathname.split('/') && location.pathname.split('/')[3]}) </div>
              <div>
                {!isLoading ?
                  <div className={classes.deviceDetails} >
                    <div style={{ display: 'flex', width: '100%' }} >
                      <div style={{ marginTop: '-10px' }}>
                        {deviceRssiVal && deviceRssiVal >= 0 && deviceRssiVal <= 1 && <SignalCellular1Bar style={{ marginLeft: "12px" }} />}
                        {deviceRssiVal && deviceRssiVal > 1 && deviceRssiVal <= 2 && <SignalCellular2Bar style={{ marginLeft: "12px" }} />}
                        {deviceRssiVal && deviceRssiVal > 2 && deviceRssiVal <= 3 && <SignalCellular3Bar style={{ marginLeft: "12px" }} />}
                        {deviceRssiVal && deviceRssiVal > 3 && deviceRssiVal <= 4 && <SignalCellular4Bar style={{ marginLeft: "12px" }} />}
                        {deviceBatteryVal >= 0 && deviceBatteryVal <= 20 && <Battery20 style={{ marginLeft: "12px" }} />}
                        {deviceBatteryVal > 20 && deviceBatteryVal <= 30 && <Battery30 style={{ marginLeft: "12px" }} />}
                        {deviceBatteryVal > 30 && deviceBatteryVal <= 50 && <Battery50 style={{ marginLeft: "12px" }} />}
                        {deviceBatteryVal > 50 && deviceBatteryVal <= 60 && <Battery60 style={{ marginLeft: "12px" }} />}
                        {deviceBatteryVal > 60 && deviceBatteryVal <= 80 && <Battery80 style={{ marginLeft: "12px" }} />}
                        {deviceBatteryVal > 80 && deviceBatteryVal <= 90 && <Battery90 style={{ marginLeft: "12px" }} />}
                        {deviceBatteryVal > 90 && deviceBatteryVal <= 100 && <BatteryFull style={{ marginLeft: "12px" }} />}
                        {deviceBatteryVal > 100 && <Power style={{ marginLeft: "12px" }} />}
                      </div>
                      <div>
                        {deviceBatteryVal && deviceBatteryVal <= 100 && <div className={classes.Fwversion}> {deviceBatteryVal + '%'} </div>}
                      </div>
                      <div>
                        {deviceData && deviceData.feed_status &&
                          <div style={{ background: (deviceData.is_active === false) ? "#4A4A4A" : "#00CCCC", borderRadius: "7px", marginTop: "-5px", width: '130px', marginLeft: '10px' }}>
                            <div style={{ color: "white", textAlign: "center", fontFamily: "'Karla', sans-serif", fontSize: "14px" }} > {(deviceData.is_active === false) ? 'Session Inactive' : 'Session Active'} </div>
                          </div>}
                        {/* <div className={(deviceData.feed_status === 'Inactive') ? classes.deviceStatusInactive : classes.deviceStatusActive}> {deviceData && deviceData.feed_status && (deviceData.feed_status === 'Inactive') ? 'Inactive' : 'Session Active'} </div>} */}
                      </div>
                      {<div className={classes.TimeLabel}> {moment.unix(lastSeenTime).format('DD-MMM-YYYY hh:mm a')} </div>}
                    </div>
                  </div> : null}
                {lastSeenTime && <div className={classes.dateFilterDiv}>
                  <DateFilter
                    options={[]}
                    label="Duration"
                    className={classes.dateFilterChip}
                    onDateChange={onDateChange}
                    defaultDate={lastSeenTime}
                  ></DateFilter>
                </div>}
              </div>

            </div>}
          </div>
        </Grid>
        <div style={{ padding: '10px 35px 20px 20px' }}>
          <Grid container spacing={2}>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              {!isLoading ? <div>
                {filteredgpsData && filteredgpsData.length > 0 ? <div>
                  <Paper className={classes.paper} elevation={8}>
                    <div style={{ height: 275 }}>
                      <DeviceMaps data={filteredgpsData}></DeviceMaps>
                    </div>
                  </Paper> </div> :
                  <div style={{ justifyContent: 'center', textAlign: 'center', fontFamily: 'Karla', fontWeight: "bold", marginTop: '10%' }} > No data to display  </div>}
              </div> : <div className={classes.mapLoader} ><CircularProgress /></div>}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              {showPopup && <DetailPopup action={'view'} currentModalState={(state) => currentModalState(state)} editMode={false} modal={true} data={popupData} />}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              {gpsData && gpsData.length > 0 && tableColumns ?
                <Paper className={classes.paper} elevation={8}>
                  <div className={classes.paperHeader}>DEVICE DATA</div>
                  {gpsData && gpsData.length > 0 && tableColumns ?
                    <div style={{ maxWidth: "100%" }} className="btmtable">
                      <MaterialTable
                        title=""
                        columns={tableColumns}
                        // columns={[
                        //   { title: "Date/Time", field: "time" },
                        //   // { title: "Avg Param 2", field: "param2" },
                        //   // { title: "Avg Param 3", field: "param3" },
                        //   // { title: "Avg Param 4", field: "param4" },
                        //   // { title: "Battery", field: "battery" },
                        //   // { title: "Latitude", field: "lat", hidden: true },
                        //   // { title: "Longitude", field: "long", hidden: true },
                        //   // { title: "Depth", field: "depth" },
                        // ]}
                        data={gpsData}
                        options={{
                          grouping: false,
                          toolbar: true,
                          paging: false,
                          exportAllData: true,
                          exportButton: true,
                          headerStyle: {
                            fontSize: "12px",
                            fontFamily: "Karla",
                            //color: "#698D8D",
                            color: "rgb(7, 71, 166)",
                            backgroundColor: 'rgb(243 243 243)',
                            //backgroundColor: "#F1FCFC",
                            padding: "4px 12px",
                          },
                          cellStyle: {
                            fontSize: "12px",
                            fontFamily: "Karla",
                            fontWeight: "bold",
                            padding: "4px 12px",
                          },
                          rowStyle: {
                            height: "auto",
                          },
                          maxBodyHeight: 325,
                          pageSize: 10,
                        }}
                        detailPanel={rowData => {
                          //console.log(rowData,'rowDataaa')
                          return (
                            <div>
                              <DetailTable data={rowData}></DetailTable>
                            </div>
                          )
                        }}
                        // onRowClick={(event, rowData, togglePanel) => togglePanel()}
                        // onRowClick={(evt, selectedRow) => {
                        //   return (<DetailPopup action={'view'} editMode={false} modal={true} data={selectedRow}/>)
                        //  }}
                        onRowClick={(evt, selectedRow) => {
                          setPopupData(selectedRow)
                          // history.push({
                          //   pathname: `/app/devices/${selectedDeviceId}/data`,
                          //   state: { detail: selectedRow }
                          // });
                        }
                        }
                      />
                    </div> : null}

                </Paper>
                : null}
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  )
}

export default DeviceDetails;
