import React, { useState, useEffect } from "react";
import DeviceMaps from './DeviceMap'
import DetailTable from './DetailTable'
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MaterialTable from "material-table";
import { useHistory, useLocation } from "react-router-dom";
import Button from "@material-ui/core/Button";
import useStyles from "./styles";
import moment from 'moment'
import DateRangeFilter from "../../components/DateRangeFilter";
import CircularProgress from '@material-ui/core/CircularProgress';
import { getDeviceSession, getDevices, useAllSessionsState } from "../../context/AllSessionsContext";

function AllSessions(props) {
  const classes = useStyles(props);
  const context = useAllSessionsState();
  const history = useHistory();
  const location = useLocation();
  const [showFullPanel, setshowFullPanel] = useState(false);
  const [devices, setdevices] = useState([])
  const [gpsData, setgpsData] = useState([])
  const [filteredgpsData, setfilteredgpsData] = useState([])
  const [isLoading, setisLoading] = useState(true)
  const [selectedDateDetails, setselectedDateDetails] = useState(null)

  useEffect(() => {
    if(location && location.fromPage && location.fromPage == 'detail_popup'){
      setselectedDateDetails(location.retainedDate)
      setgpsData(location.retainedData)
      setdevices(location.retainedDeviceList)
      setshowFullPanel(true)
      setisLoading(false)
    }
    else{
      getDevices(context.setData);
    }
  }, [context.setData]);

  useEffect(() => {
    if (context.data.devices) {
      setdevices(context.data.devices.results)
    }
  }, [context.data.devices]);
  // useEffect(() => {
  //   if (context.data.gpsData) {
  //     let gData = context.data.gpsData.map((item, index) => {
  //       return { geometry: [parseFloat(item.devicelocation.long), parseFloat(item.devicelocation.lat)], longitude: parseFloat(item.devicelocation.long), latitude: parseFloat(item.devicelocation.lat), "name": item.device_name, "aliasName": item.alias_name, feed_status: item.feed_status, deviceId: item.device_id, location: item.location, is_active: item.is_active, battery: item.battery_level, lastSeen: item.last_seen }
  //     });
  //   }
  // }, [context.data.gpsData]);

  useEffect(() => {
    if (context.data.streamData) {
      //setisLoading(true)
      setgpsData([])
      if (context.data.streamData.length > 0) {
        let gData = context.data.streamData.map((item, index) => {
          if (item.notes && item.notes.avgValues) {
            var paramavg1 = item.notes.avgValues.avg1;
            var paramavg2 = item.notes.avgValues.avg2;
            var parammax1 = item.notes.avgValues.max1;
            var parammax2 = item.notes.avgValues.max2;
            var deviceName = ''
            var depthValue = (item.notes.depth) ? item.notes.depth : 0.3

            if (item.gps && item.gps.coordinates && item.gps.coordinates[0] && item.gps.coordinates[1]) {
              if (item.device_id) {
                var deviceArr = []
                deviceArr = devices.filter(f => f.device_id === item.device_id)
                if (deviceArr && deviceArr[0]) {
                  deviceName = deviceArr[0].alias_name
                }
              }
              return {
                avg1: (paramavg1 > 0.0) ? parseFloat(paramavg1).toFixed(6) : '-',
                avg2: (paramavg2 > 0.0) ? parseFloat(paramavg2).toFixed(6) : '-',
                max1: (parammax1 > 0.0) ? parseFloat(parammax1).toFixed(6) : '-',
                max2: (parammax2 > 0.0) ? parseFloat(parammax2).toFixed(6) : '-',
                notes: item.notes, depth: depthValue, deviceName: deviceName,
                completed_at: item.created_at, last_triggered_at: item.last_triggered_at,
                completed_at_formatted: moment(item.created_at).format('DD-MM-YYYY hh:mm a') + '-' + moment(item.last_triggered_at).format('DD-MM-YYYY hh:mm a'), deviceId : item.device_id,
                sessionId: item.id, sessionData: item.sessionData, geometry: item.gps.coordinates, longitude: parseFloat(item.gps.coordinates[0]), latitude: parseFloat(item.gps.coordinates[1]), time: moment(item.created_at).format('DD-MMM hh:mm a')
              }
            }
            else {
              if (item.sessionData && item.sessionData.length > 0) {
                if (item.sessionData[0].gps && item.sessionData[0].gps.coordinates && item.sessionData[0].gps.coordinates[0] && item.sessionData[0].gps.coordinates[1]) {
                  if (item.device_id) {
                    var deviceArr = []
                    deviceArr = devices.filter(f => f.device_id === item.device_id)
                    if (deviceArr && deviceArr[0]) {
                      deviceName = deviceArr[0].alias_name
                    }
                  }
                  return {
                    avg1: (paramavg1 > 0.0) ? parseFloat(paramavg1).toFixed(6) : '-',
                    avg2: (paramavg2 > 0.0) ? parseFloat(paramavg2).toFixed(6) : '-',
                    max1: (parammax1 > 0.0) ? parseFloat(parammax1).toFixed(6) : '-',
                    max2: (parammax2 > 0.0) ? parseFloat(parammax2).toFixed(6) : '-',
                    notes: item.notes, depth: depthValue, deviceName: deviceName,
                    completed_at: item.created_at, last_triggered_at: item.last_triggered_at,
                    completed_at_formatted: moment(item.created_at).format('DD-MM-YYYY hh:mm a') + '-' + moment(item.last_triggered_at).format('DD-MM-YYYY hh:mm a'), deviceId : item.device_id,
                    sessionId: item.id, sessionData: item.sessionData, geometry: item.sessionData[0].gps.coordinates, longitude: item.sessionData[0].gps.coordinates[0], latitude: item.sessionData[0].gps.coordinates[1], time: moment(item.created_at).format('DD-MMM hh:mm a')
                  }
                }
                else {
                  if (item.device_id) {
                    var deviceArr = []
                    deviceArr = devices.filter(f => f.device_id === item.device_id)
                    if (deviceArr && deviceArr[0]) {
                      deviceName = deviceArr[0].alias_name
                    }
                  }
                  return {
                    avg1: (paramavg1 > 0.0) ? parseFloat(paramavg1).toFixed(6) : '-',
                    avg2: (paramavg2 > 0.0) ? parseFloat(paramavg2).toFixed(6) : '-',
                    max1: (parammax1 > 0.0) ? parseFloat(parammax1).toFixed(6) : '-',
                    max2: (parammax2 > 0.0) ? parseFloat(parammax2).toFixed(6) : '-',
                    notes: item.notes, depth: depthValue, deviceName: deviceName,
                    completed_at: item.created_at, last_triggered_at: item.last_triggered_at,
                    completed_at_formatted: moment(item.created_at).format('DD-MM-YYYY hh:mm a') + '-' + moment(item.last_triggered_at).format('DD-MM-YYYY hh:mm a'), deviceId : item.device_id,
                    sessionId: item.id, sessionData: item.sessionData, geometry: [], longitude: null, latitude: null, time: moment(item.created_at).format('DD-MMM hh:mm a')
                  }
                }
              }
              else {
                if (item.device_id) {
                  var deviceArr = []
                  deviceArr = devices.filter(f => f.device_id === item.device_id)
                  if (deviceArr && deviceArr[0]) {
                    deviceName = deviceArr[0].alias_name
                  }
                }
                return {
                  avg1: (paramavg1 > 0.0) ? parseFloat(paramavg1).toFixed(6) : '-',
                  avg2: (paramavg2 > 0.0) ? parseFloat(paramavg2).toFixed(6) : '-',
                  max1: (parammax1 > 0.0) ? parseFloat(parammax1).toFixed(6) : '-',
                  max2: (parammax2 > 0.0) ? parseFloat(parammax2).toFixed(6) : '-',
                  notes: item.notes, depth: depthValue, deviceName: deviceName,
                  completed_at: item.created_at, last_triggered_at: item.last_triggered_at,
                  completed_at_formatted: moment(item.created_at).format('DD-MM-YYYY hh:mm a') + '-' + moment(item.last_triggered_at).format('DD-MM-YYYY hh:mm a'), deviceId : item.device_id,
                  sessionId: item.id, sessionData: item.sessionData, geometry: [], longitude: null, latitude: null, time: moment(item.created_at).format('DD-MMM hh:mm a')
                }
              }
            }
          }
          else {
            var paramavg1 = 0;
            var paramavg2 = 0;
            var parammax1 = 0;
            var parammax2 = 0;
            var depthVal = 0.3;
            var count = 0;
            var paramavg1count = 0;
            var paramavg2count = 0;
            var parammax1count = 0;
            var parammax2count = 0;
            if (item.sessionData) {
              count = item.sessionData.length
              item.sessionData.map((data, index1) => {
                Object.entries(data.data).forEach(([key, value]) => {
                  if (key.includes('paramavg1')) {
                    paramavg1 = (paramavg1 + value)
                    paramavg1count = paramavg1count + 1
                  }
                  else if (key.includes('paramavg2')) {
                    paramavg2 = (paramavg2 + value)
                    paramavg2count = paramavg2count + 1
                  }
                  else if (key.includes('parammax1')) {
                    parammax1 = (parammax1 + value)
                    parammax1count = parammax1count + 1
                  }
                  else if (key.includes('parammax2')) {
                    parammax2 = (parammax2 + value)
                    parammax2count = parammax2count + 1
                  }
                })
              })
            }
            paramavg1 = paramavg1 / paramavg1count
            paramavg2 = paramavg2 / paramavg2count
            parammax1 = parammax1 / parammax1count
            parammax2 = parammax2 / parammax2count
            
            if (item.gps && item.gps.coordinates && item.gps.coordinates[0] && item.gps.coordinates[1]) {
              if (item.device_id) {
                var deviceArr = []
                deviceArr = devices.filter(f => f.device_id === item.device_id)
                if (deviceArr && deviceArr[0]) {
                  deviceName = deviceArr[0].alias_name
                }
              }
              return {
                avg1: (paramavg1 > 0.0) ? parseFloat(paramavg1).toFixed(6) : '-',
                avg2: (paramavg2 > 0.0) ? parseFloat(paramavg2).toFixed(6) : '-',
                max1: (parammax1 > 0.0) ? parseFloat(parammax1).toFixed(6) : '-',
                max2: (parammax2 > 0.0) ? parseFloat(parammax2).toFixed(6) : '-',
                notes: item.notes, depth: depthVal, deviceName: deviceName,
                completed_at: item.created_at, last_triggered_at: item.last_triggered_at,
                completed_at_formatted: moment(item.created_at).format('DD-MM-YYYY hh:mm a') + '-' + moment(item.last_triggered_at).format('DD-MM-YYYY hh:mm a'), deviceId : item.device_id,
                sessionId: item.id, sessionData: item.sessionData, geometry: item.gps.coordinates, longitude: parseFloat(item.gps.coordinates[0]), latitude: parseFloat(item.gps.coordinates[1]), time: moment(item.created_at).format('DD-MMM hh:mm a')
              }
            }
            else {
              if (item.sessionData && item.sessionData.length > 0) {
                if (item.sessionData[0].gps && item.sessionData[0].gps.coordinates && item.sessionData[0].gps.coordinates[0] && item.sessionData[0].gps.coordinates[1]) {
                  if (item.device_id) {
                    var deviceArr = []
                    deviceArr = devices.filter(f => f.device_id === item.device_id)
                    if (deviceArr && deviceArr[0]) {
                      deviceName = deviceArr[0].alias_name
                    }
                  }
                  return {
                    avg1: (paramavg1 > 0.0) ? parseFloat(paramavg1).toFixed(6) : '-',
                    avg2: (paramavg2 > 0.0) ? parseFloat(paramavg2).toFixed(6) : '-',
                    max1: (parammax1 > 0.0) ? parseFloat(parammax1).toFixed(6) : '-',
                    max2: (parammax2 > 0.0) ? parseFloat(parammax2).toFixed(6) : '-',
                    notes: item.notes, depth: depthVal, deviceName: deviceName,
                    completed_at: item.created_at, last_triggered_at: item.last_triggered_at,
                    completed_at_formatted: moment(item.created_at).format('DD-MM-YYYY hh:mm a') + '-' + moment(item.last_triggered_at).format('DD-MM-YYYY hh:mm a'), deviceId : item.device_id,
                    sessionId: item.id, sessionData: item.sessionData, geometry: item.sessionData[0].gps.coordinates, longitude: item.sessionData[0].gps.coordinates[0], latitude: item.sessionData[0].gps.coordinates[1], time: moment(item.created_at).format('DD-MMM hh:mm a')
                  }
                }
                else {
                  if (item.device_id) {
                    var deviceArr = []
                    deviceArr = devices.filter(f => f.device_id === item.device_id)
                    if (deviceArr && deviceArr[0]) {
                      deviceName = deviceArr[0].alias_name
                    }
                  }
                  return {
                    avg1: (paramavg1 > 0.0) ? parseFloat(paramavg1).toFixed(6) : '-',
                    avg2: (paramavg2 > 0.0) ? parseFloat(paramavg2).toFixed(6) : '-',
                    max1: (parammax1 > 0.0) ? parseFloat(parammax1).toFixed(6) : '-',
                    max2: (parammax2 > 0.0) ? parseFloat(parammax2).toFixed(6) : '-',
                    notes: item.notes, depth: depthVal, deviceName: deviceName,
                    completed_at: item.created_at, last_triggered_at: item.last_triggered_at,
                    completed_at_formatted: moment(item.created_at).format('DD-MM-YYYY hh:mm a') + '-' + moment(item.last_triggered_at).format('DD-MM-YYYY hh:mm a'), deviceId : item.device_id,
                    sessionId: item.id, sessionData: item.sessionData, geometry: [], longitude: null, latitude: null, time: moment(item.created_at).format('DD-MMM hh:mm a')
                  }
                }
              }
              else {
                if (item.device_id) {
                  var deviceArr = []
                  deviceArr = devices.filter(f => f.device_id === item.device_id)
                  if (deviceArr && deviceArr[0]) {
                    deviceName = deviceArr[0].alias_name
                  }
                }
                return {
                  avg1: (paramavg1 > 0.0) ? parseFloat(paramavg1).toFixed(6) : '-',
                  avg2: (paramavg2 > 0.0) ? parseFloat(paramavg2).toFixed(6) : '-',
                  max1: (parammax1 > 0.0) ? parseFloat(parammax1).toFixed(6) : '-',
                  max2: (parammax2 > 0.0) ? parseFloat(parammax2).toFixed(6) : '-',
                  notes: item.notes, depth: depthVal, deviceName: deviceName,
                  completed_at: item.created_at, last_triggered_at: item.last_triggered_at,
                  completed_at_formatted: moment(item.created_at).format('DD-MM-YYYY hh:mm a') + '-' + moment(item.last_triggered_at).format('DD-MM-YYYY hh:mm a'), deviceId : item.device_id,
                  sessionId: item.id, sessionData: item.sessionData, geometry: [], longitude: null, latitude: null, time: moment(item.created_at).format('DD-MMM hh:mm a')
                }
              }
            }
          }
        });
        if(gData && gData.length > 0){
          var filteredData = gData.filter(f => f !== undefined)
          filteredData = gData.filter(f => (f && f.avg1 && f.avg1 !== '-') || (f && f.avg2 && f.avg2 !== '-') || (f && f.max1 &&  f.max1 !== '-') || (f && f.max2 && f.max2 !== '-'))
          setgpsData(filteredData)
        }
        else if (gData.length === 0) {
          setgpsData([])
          setfilteredgpsData([])
          setisLoading(false)
        }
       // setisLoading(false)
      }
      else if (context.data.streamData.length === 0) {
        setgpsData([])
        setfilteredgpsData([])
        setisLoading(false)
      }
    }
  }, [context.data.streamData]);

  useEffect(() => {
    if (gpsData && gpsData.length > 0) {
      var filteredItems = gpsData.filter(f => f.geometry && f.geometry.length > 0)
      setfilteredgpsData(filteredItems)
      setisLoading(false)
    }
    else if(gpsData && gpsData.length === 0){
      setfilteredgpsData([])
      setisLoading(false)            
    }
  }, [gpsData])

  const onDateChange = (dateRange) => {
    if(location && location.fromPage === 'detail_popup'){
      setisLoading(true)
      getDeviceSession(context.setData, location.retainedDate.selectedDate);
    }
    else{
      if (!selectedDateDetails ||
        !moment(selectedDateDetails.selectedDate[0]).isSame(dateRange[0], 'day') ||
        !moment(selectedDateDetails.selectedDate[1]).isSame(dateRange[1], 'day')) {
        setselectedDateDetails({
          selectedDate: dateRange
        })
        setisLoading(true)
        getDeviceSession(context.setData, dateRange);
      }
    }
  }
  const showMore = () => {
    setshowFullPanel(!showFullPanel);
  };
  return (
    <>
      {isLoading && <div style={{ height: '88vh', display: 'flex', justifyContent : 'center', alignItems : 'center' }}> <CircularProgress /> </div>}

      {!isLoading && filteredgpsData && filteredgpsData.length === 0 && <div>
          <div style={{ height: '88vh' }}>
           <div style={{ justifyContent: 'center', textAlign: 'center', fontFamily: 'Karla', fontWeight: "bold", marginTop: '15%' }} > No data to display  </div>
          </div> 
       </div> }

      {!isLoading && filteredgpsData && filteredgpsData.length > 0 && <div>
          <div style={{ height: '88vh' }}>
            <DeviceMaps data={filteredgpsData}></DeviceMaps>
          </div> 
       </div> }

      {<div className={classes.bottompanel + (!showFullPanel ? "" : " " + classes.fullpanel)} >
        <div style={{ textAlign: "center" }}>
          {!showFullPanel ? (
            <Button
              variant="contained"
              color="default"
              className={classes.morebtn}
              onClick={showMore}
            >
              SEE MORE <ExpandLessIcon></ExpandLessIcon>
            </Button>
          ) : (
              <Button
                variant="contained"
                color="default"
                className={classes.morebtn}
                onClick={showMore}
              >
                SEE LESS <ExpandMoreIcon></ExpandMoreIcon>
              </Button>
            )}
        </div>
        <div style={{ maxWidth: "100%", height: 300 }} className="btmtable">
          <DateRangeFilter
            // options={[]}
            label="Duration"
            className={classes.filter}
            onDateChange={onDateChange}
          ></DateRangeFilter>
          {!isLoading ? <MaterialTable
            title="Sessions Overview"
            columns={[
              { title: "Time", export: false,  field: "completed_at", render: rowData => moment(rowData.completed_at).format('DD-MMM hh:mm a') + '-' + moment(rowData.last_triggered_at).format('hh:mm a') },
              { title: "Time", export: true,  hidden: true,  field: "completed_at_formatted"},            
              {
                title: 'Device Name', field: 'deviceName',
              },
              {
                title: 'Avg CR (in um/y)', field: 'avg1',
              },
              {
                title: 'Avg CR (in mpy)', field: 'avg2',
              },
              {
                title: 'Max CR (in um/y)', field: 'max1',
              },
              {
                title: 'Max CR (in mpy)', field: 'max2',
              },
              {
                title: 'Depth(in m)', field: 'depth',
              },
              {
                title: 'GPS Coordinates', field: 'geometry',
                render: rowData => (rowData.geometry && rowData.geometry.length > 0) ? 'Lat : ' + rowData.geometry[1] + ', Long : ' + rowData.geometry[0] : '-'
              }
              // { title: "Last Activity", field: "last_triggered_at", render : rowData => moment(rowData.last_triggered_at).format('DD-MMM hh:mm a')},
            ]}
            data={gpsData}
            options={{
              grouping: false,
              paging: false,
              exportAllData: true,
              exportButton: true,
              headerStyle: {
                fontSize: "12px",
                fontFamily: "Karla",
                color: "rgb(7, 71, 166)",
                backgroundColor: 'rgb(243 243 243)',
                padding: "4px 12px",
              },
              cellStyle: {
                fontSize: "12px",
                fontFamily: "Karla",
                fontWeight: "bold",
                padding: "4px 12px",
              },
              rowStyle: {
                height: "auto",
              },
              pageSize: 10,
            }}
            // detailPanel={rowData => {
            //   return (
            //     <div>
            //       <DetailTable data={rowData}></DetailTable>
            //     </div>
            //   )
            // }}
          onRowClick={(evt, selectedRow) => {
            history.push({
              pathname: `/app/devices/${selectedRow.deviceId}`,
              state: { detail: selectedRow, fromPage : 'all_sessions', retainedDate : selectedDateDetails, retainedData : gpsData, retainedDeviceList: devices }
            });
          }}
          /> :  <div style={{ justifyContent: 'center', textAlign: 'center', fontFamily: 'Karla', fontWeight: "bold", marginTop: '15%' }} > Loading... </div>
          }
        </div>
      </div>}
    </>
  )
}

export default AllSessions;
