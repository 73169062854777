import React from "react";
import { Route, Switch,withRouter } from "react-router-dom";
import classnames from "classnames";
import useStyles from "./styles";
import Header from "../Header";
import Sidebar from "../Sidebar";
import Dashboard from "../../pages/dashboard";
import DeviceDetails from "../../pages/deviceDetails";
import AllSessions from "../../pages/allSessions";
import { useLayoutState } from "../../context/LayoutContext";
import { DashboardProvider } from "../../context/DashboardContext"
import { DeviceProvider } from "../../context/DeviceContext";
import { AllSessionsProvider } from "../../context/AllSessionsContext"

function Layout(props) {
  var classes = useStyles();
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <Switch>
            <Route path="/app/dashboard"> 
              <DashboardProvider>
                <Dashboard/>
             </DashboardProvider>
            </Route>
            <Route path="/app/allSessions"> 
              <AllSessionsProvider>
                <AllSessions/>
             </AllSessionsProvider>
            </Route>
            <Route path="/app/devices/:deviceId">  
              <DeviceProvider>
                <DeviceDetails/>
              </DeviceProvider>
            </Route>
          </Switch>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
