import defaultTheme from "./default";

import { createMuiTheme } from "@material-ui/core";

const overrides = {
  typography: {
    fontFamily: "Karla, sans-serif",
    h1: {
      fontSize: "3rem",
    },
    h2: {
      fontSize: "2rem",
    },
    h3: {
      fontSize: "1.64rem",
    },
    h4: {
      fontSize: "1.5rem",
    },
    h5: {
      // fontSize: "1.385rem",
      //textTransform: "capitalize",
      fontFamily: "Karla, sans-serif",
      //fontSize: "18px",
      fontWeight: "bold",
      marginTop : "1%",
      fontSize: "15.42px", 
      color: "#595959", 
      lineHeight : "22px",
      textTransform : "uppercase"
    },
    h6: {
      fontSize: "1.242rem",
    },
  },
};

export default {
  default: createMuiTheme({ ...defaultTheme, ...overrides }),
};
