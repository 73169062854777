import * as React from 'react';
import { Component } from 'react';
import { render } from 'react-dom';
import MapGL, { Marker, NavigationControl, WebMercatorViewport } from 'react-map-gl';
import Pins from './pins';

const TOKEN = 'pk.eyJ1IjoiYW5hYmF1YiIsImEiOiJjazgxNndyY2MwM2puM21wdDZldjRqem44In0.qhIfrQkyF5JfLPM-T9Gr5w'; // Set your mapbox token here

const navStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  padding: '10px'
};
const applyToArray = (func, array) => func.apply(Math, array)

const getBoundsForPoints = (cornersLongLat) => {
  const viewport = new WebMercatorViewport({ width: window.innerWidth - 125, height: 225 })
    .fitBounds(cornersLongLat, { paddingLeft: (64 + 20 + 450) }) // Can also use option: offset: [0, -100]
  const { longitude, latitude, zoom } = viewport
  return { longitude, latitude, zoom }
}
export default class PreviewEditMap extends Component {
  constructor(props) {
    super(props);
    const mapData = props.data
    this.state = {
      viewport: {
        latitude: (mapData.latitude) ? mapData.latitude : 1.2887,
        longitude: (mapData.longitude) ? mapData.longitude : 103.7911,
        zoom: 16,
        bearing: 0,
        pitch: 0
      },
      marker: {
        latitude: mapData.latitude,
        longitude: mapData.longitude
      },
      data: props.data,
      events: {}
    };
  }

  _updateViewport = viewport => {
    this.setState({ viewport });
  };

  componentDidMount() {
    if (this.state.data) {
      this.setViewPort();
    }
  }
  setViewPort() {
    let { data } = this.props;

    let pointsLong = [];
    let pointsLat = [];
    if (data) {
      if (data.geometry && data.geometry[0] && data.geometry[1]) {
        pointsLong.push(data.geometry[0])
        pointsLat.push(data.geometry[1])
      }
      let cornersLongLat = [
        [applyToArray(Math.min, pointsLong), applyToArray(Math.min, pointsLat)],
        [applyToArray(Math.max, pointsLong), applyToArray(Math.max, pointsLat)]
      ]
      const bounds = getBoundsForPoints(cornersLongLat)
      let currentViewState = { ...bounds, bearing: 0, pitch: 15, zoom: 17, transitionDuration: 1000 };
      this.setState({ viewport: currentViewState });
    }
  }

  _logDragEvent(name, event) {
    this.setState({
      events: {
        ...this.state.events,
        [name]: event.lngLat
      }
    });
  }

  _onMarkerDragStart = event => {
    this._logDragEvent('onDragStart', event);
  };

  _onMarkerDrag = event => {
    this._logDragEvent('onDrag', event);
  };

  _onMarkerDragEnd = event => {
    this._logDragEvent('onDragEnd', event);
    this.setState({
      marker: {
        longitude: event.lngLat[0],
        latitude: event.lngLat[1]
      }
    });
    var tempMarker = []
    tempMarker = {
      longitude: event.lngLat[0],
      latitude: event.lngLat[1]
    }
    this.props._onMarkerDragEnd(tempMarker)
  };

  render() {
    const { viewport, marker } = this.state;

    return (
      <MapGL
        {...viewport}
        width="100%"
        height="100%"
        mapStyle="mapbox://styles/sla/cjnci1u153tqz2ro5eka7t0ef"
        onViewportChange={this._updateViewport}
        mapboxApiAccessToken={TOKEN}
      >
        {marker && marker.longitude && marker.latitude &&
          <Marker
            longitude={marker.longitude}
            latitude={marker.latitude}
            offsetTop={-20}
            offsetLeft={-10}
            draggable
            onDragStart={this._onMarkerDragStart}
            onDrag={this._onMarkerDrag}
            onDragEnd={this._onMarkerDragEnd}
          >
            <Pins size={15} />
          </Marker>}

        <div className="nav" style={navStyle}>
          <NavigationControl onViewportChange={this._updateViewport} />
        </div>
      </MapGL>
    );
  }
}
