import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import PreviewMap from './PreviewMap'
import PreviewEditMap from './PreviewEditMap'
import TextField from '@material-ui/core/TextField';
import { Grid, Paper } from "@material-ui/core";
import PreviewTable from './PreviewTable';
import Tile from "../../components/Tile";
import { useHistory, useLocation } from "react-router-dom";
import { useDeviceState, getGPSData, patchGPSData, deleteDeviceSession, getDeviceData, getDeviceSession, getDeviceSessionData } from "../../context/DeviceContext";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PreviewAddMap from './PreviewAddMap';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DetailPopup(props) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const context = useDeviceState();
  const data = props.data
  const detailData = props.data.sessionData
  const popupMode = props.action
  const editMode = props.editMode
  const dialog = props.modal
  const [modalState, setmodalState] = React.useState(dialog)
  const [open, setOpen] = React.useState(true);
  const [editModeVal, seteditModeVal] = React.useState(editMode);
  const [newGPSCoords, setnewGPSCoords] = React.useState([])
  const [depthVal, setdepthVal] = React.useState('0.3')
  const [dataArr, setdataArr] = React.useState([])
  const [filteredData, setfilteredData] = React.useState([])
  const [selectedDeviceId, setselectedDeviceId] = useState(null);
  const [deletePopup, setdeletePopup] = React.useState(false);
  
  const handleClickOpen = () => {
    setOpen(true);
  };
  const currentModalState = (state) => {
    props.currentModalState(state)
  };
  const handleClose = () => {
    currentModalState(false)
    setOpen(false);
    setmodalState(false)
    seteditModeVal(false);
    if(location && location.state && location.state.fromPage && location.state.fromPage == 'all_sessions'){
      history.push({
        pathname: `/app/allSessions`, retainedDate : location.state.retainedDate, retainedData : location.state.retainedData, retainedDeviceList: location.state.retainedDeviceList, fromPage: 'detail_popup'
      });
    }
  };

  const onClickSave = () => {
    setOpen(false);
    patchGPSData(context.setData, data.sessionId, dataArr, selectedDeviceId);
    setmodalState(false)
    seteditModeVal(false);
    if(location && location.state && location.state.fromPage && location.state.fromPage == 'all_sessions'){
      history.push({
        pathname: `/app/allSessions`, retainedDate : location.state.retainedDate, retainedData : location.state.retainedData, retainedDeviceList: location.state.retainedDeviceList, fromPage: 'detail_popup'
      });
    }
  };

  const onClickEdit = () => {
    seteditModeVal(true);
  };
  const onClickDelete = () => {
    setdeletePopup(true);
  };
  const onConfirmDelete = () => {
    setdeletePopup(false);
    setOpen(false);
    deleteDeviceSession(context.setData, data.sessionId, selectedDeviceId);
    if(location && location.state && location.state.fromPage && location.state.fromPage == 'all_sessions'){
      history.push({
        pathname: `/app/allSessions`, retainedDate : location.state.retainedDate, retainedData : location.state.retainedData, retainedDeviceList: location.state.retainedDeviceList, fromPage: 'detail_popup'
      });
    }
  };
  const onCloseDelete = () => {
    setdeletePopup(false);
  };

  useEffect(() => {
    setOpen(true)
    var deviceID = location.pathname.split('/')
    setselectedDeviceId(deviceID[3])
    if (data) {
      var tempArr = []
      tempArr.push(data)
      var filterItems = tempArr.filter(f => f.geometry && f.geometry.length > 0)
      setfilteredData(filterItems)
      console.log(location.state,'data')
    }
  }, [data, context.setData]);

  useEffect(() => {
    var tempArr = {}
    var gpsData = {}
    var depthData = {}
    setdataArr([])
    if (newGPSCoords && newGPSCoords.longitude && newGPSCoords.latitude) {
      gpsData = {
        "type": "Point",
        "coordinates": [
          newGPSCoords.longitude, newGPSCoords.latitude
        ]
      }
      tempArr = { gps: gpsData }
    }
    if (depthVal) {
      depthData = depthVal
      tempArr = { ...tempArr, depthData }
    }
    setdataArr(tempArr)
    //}
  }, [newGPSCoords, depthVal]);

  const _onMarkerDragEnd = (data) => {
    setnewGPSCoords(data)
  }

  return (
    <div>
      <Dialog  PaperProps={{style: {backgroundColor: 'rgb(247,247,247)'}}} fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
             {data.time} {location && location.state && location.state.fromPage && location.state.fromPage == 'all_sessions' && ' (' + location.state.detail.deviceName + ')'}
            </Typography>
            {editModeVal ?
              <div>
                <Button autoFocus color="inherit" onClick={onClickSave}>
                  Save
                  </Button>
              </div> : <div>
                <Button autoFocus color="inherit" onClick={onClickEdit}>
                  Edit
                </Button>
                <Button autoFocus color="inherit" onClick={onClickDelete}>
                  Delete Session
                </Button>
              </div>
            }

          </Toolbar>
        </AppBar>
        <div style={{ padding: '10px 35px 20px 20px' }}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div>
                <Dialog
                  open={deletePopup}
                  onClose={onCloseDelete}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete this session?"}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Deleting this session will permanant, cannot be reverted.
                  </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={onCloseDelete} color="primary">
                      Cancel
                  </Button>
                    <Button onClick={onConfirmDelete} color="primary" autoFocus>
                      Delete
                  </Button>
                  </DialogActions>
                </Dialog>
              </div>
              <div style={{ display: "flex", padding: "12px" }}>
                <Tile
                  label="Avg CR"
                  unit="(in um/y)"
                  value={data.avg1}
                  className={classes.tile}
                ></Tile>
                <Tile
                  label="Avg CR"
                  unit="(in mpy)"
                  value={data.avg2}
                  className={classes.tile}
                  //type="outline"
                  color="#63C292"
                ></Tile>
                <Tile
                  label="Max CR"
                  unit="(in um/y)"
                  value={data.max1}
                  className={classes.tile}
                  //type="outline"
                  color="#EB4559"
                ></Tile>
                <Tile
                  label="Max CR"
                  unit="(in mpy)"
                  value={data.max2}
                  className={classes.tile}
                  //type="outline"
                  color="#EB4559"
                ></Tile>
                {editModeVal ?
                  <TextField style={{ width: '30%' }} required id="group-name" value={depthVal} onChange={(e) => setdepthVal(e.target.value)} label="Depth" />
                  : <Tile
                    label="Depth"
                    unit="(in m) "
                    value={data.depth}
                    className={classes.tile}
                  ></Tile>}
              </div>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              {filteredData && filteredData.length > 0 ?
                <Paper className={classes.paper} elevation={8}>
                  {editModeVal ?
                    <div style={{ height: 300 }}>
                      <PreviewEditMap _onMarkerDragEnd={(data) => _onMarkerDragEnd(data)} data={data}></PreviewEditMap> :
                 </div> :
                    <div style={{ height: 300 }}>
                      <PreviewMap data={data}></PreviewMap>
                    </div>}
                </Paper> :
                <div> {editModeVal ? <div style={{ height: 300 }}>
                  <PreviewAddMap _onMarkerDragEnd={(data) => _onMarkerDragEnd(data)} ></PreviewAddMap>
                </div> :
                  <div style={{ justifyContent: 'center', textAlign: 'center', fontFamily: 'Karla', fontWeight: "bold", marginTop: '10%' }} > No GPS Data to display, <Button onClick={onClickEdit} color="primary" autoFocus> Click Here</Button> to Add a data point   </div>}
                </div>
              }
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <PreviewTable data={detailData}></PreviewTable>
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
}