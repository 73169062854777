import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import * as Icons from "@material-ui/icons";
import useStyles from "./styles";
import SidebarLink from "./components/SidebarLink/SidebarLink";
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

const structure = [
  { id: 6, type: "divider" },
  { id: 0, label: "Dashboard", link: "/app/dashboard", icon: <Icons.Map /> },
  { id: 1, label: "Sessions Overview", link: "/app/allSessions", icon: <Icons.Update /> },
  { id: 7, type: "divider" },
];

function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var layoutState = useLayoutState();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.mobileBackButton}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonCollapse,
          )}>
          {layoutState.isSidebarOpened ? (
            <Icons.MenuOpen
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }} style={{ color: "rgb(7, 71, 166)" }}
            />
          ) : (
              <Icons.Dehaze
                classes={{
                  root: classNames(
                    classes.headerIcon,
                    classes.headerIconCollapse,
                  ),
                }} style={{ color: "rgb(7, 71, 166)" }}
              />
            )}
        </IconButton>

      </div>
      {isSidebarOpened === true ?
        <div style={{ marginTop: "5%" }}>
          <img style={{ marginLeft: "15%" }} src="icon.png" width="41" height="40" />
          <Typography style={{ marginTop: "5%", float: "right", marginRight: "25%", fontFamily: "'Karla', sans-serif", fontWeight: "bold" }} >Hi, {localStorage.getItem("first_name")}</Typography>
        </div>
        : null}
      <List className={classes.sidebarList}>
        {structure.map((link) => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
