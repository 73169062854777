import React from "react";
import axios from "axios";
import moment from 'moment'

const DeviceContext = React.createContext();
const rootReducer = (state, action) => {
    switch (action.type) {
        case "UPDATE_DEVICES":
            return {
                isLoaded: true,
                devices: action.payload
            };
        case "GET_DEVICES":
            return {
                ...state,
                isLoaded: true,
                device: action.payload,
                error: ''
            };
        case "GET_DEVICES_ERROR":
            return {
                ...state,
                isLoaded: true,
                device: null,
                error: action.payload
            };
        case "GET_FEED_DATA":
            return {
                ...state,
                feedData: action.payload,
                error: ''
            };
        case "GET_GPS_DATA":
            return {
                ...state,
                gpsData: action.payload,
                error: ''
            };
        case "GET_STREAM_DEVICES":
            return {
                ...state,
                isLoaded: true,
                streamDevices: action.payload,
                streamDeviceserror: ''
            };
        case "GET_STREAM_DEVICES_ERROR":
            return {
                ...state,
                isLoaded: true,
                streamDevices: null,
                streamDeviceserror: action.payload
            };
        case "GET_STREAM_DATA":
            return {
                ...state,
                isLoaded: true,
                streamData: action.payload,
                streamDataerror: ''
            };
        case "GET_STREAM_DATA_ERROR":
            return {
                ...state,
                isLoaded: true,
                streamData: null,
                streamDataerror: action.payload
            };
        case "UPDATE_STREAM_DATA":
            return {
                ...state,
                isLoaded: true,
                updatestreamData: action.payload,
                updatestreamDataerror: ''
            };
        case "UPDATE_STREAM_DATA_ERROR":
            return {
                ...state,
                isLoaded: true,
                updatestreamData: null,
                updatestreamDataerror: action.payload
            };
    }
}
const DeviceProvider = ({ children }) => {
    const [data, setData] = React.useReducer(rootReducer, {
        isLoaded: false,
        device: null,
        gpsData: null,
        streamDevices: null,
        streamData: null,
        updatestreamData: null,
        error: ''
    });
    return (
        <DeviceContext.Provider value={{ data, setData }}>
            {children}
        </DeviceContext.Provider>
    );
};

const useDeviceState = () => {
    const context = React.useContext(DeviceContext);
    return context;
};

export function getDeviceSession(dispatch, device_id, startDate, endDate) {
    var start = moment(startDate).format('YYYY-MM-DD')
    var end = moment(endDate).format('YYYY-MM-DD')

    var startDate = start + ' 00:00:00'
    var endDate = end + ' 23:59:00'

    var utcstartDate = moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss')
    var utcendDate = moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss')

    return axios.get(`/${localStorage.getItem("organization")}/devices/${device_id}/stream-session?alarm__last_triggered_at__range=${utcstartDate},${utcendDate}`).then(res => {
        let devicedata = res.data.results;
        if (res.data && res.data.results) {
            let reqArr = [];
            for (let i = 0; i < res.data.results.length; i++) {
                reqArr.push(axios.get(`/${localStorage.getItem("organization")}/devices/${device_id}/stream-session-data/${res.data.results[i].id}`))
            }
            axios.all(reqArr).then(axios.spread((...responses) => {
                for (let r = 0; r < responses.length; r++) {
                    let res = responses[r];
                    for (let f = 0; f < devicedata.length; f++) {
                        if (res && res.data) {
                            let device = devicedata[r];
                            device.sessionData = res.data.results;
                        }
                    }
                }
                dispatch({ type: "GET_STREAM_DEVICES", payload: devicedata });
            }))
        }
    })
}
export function patchGPSData(dispatch, sessionId, gpsData, device_id) {
    var values = {}
    var updatedValues = {}
    var notes = {}
    updatedValues = gpsData
    return axios.get(`/${localStorage.getItem("organization")}/devices/${device_id}/stream-session`).then(res => {
        var response = res.data.results
        var results = response.map((data, index) => {
            if (data.id === sessionId) {
                if (data.notes) {
                    if (updatedValues.depthData) {
                        var depthData = updatedValues.depthData
                        notes = { notes: { ...data.notes, depth: depthData } }
                    }
                    if (updatedValues.gps) {
                        notes = { gps: updatedValues.gps, notes: { ...data.notes } }
                    }
                    values = notes
                }
                return axios.patch(`/${localStorage.getItem("organization")}/devices/${device_id}/stream-session/${sessionId}`, values).then(res => {
                    //dispatch({ type: "UPDATED_STREAM_DATA", payload: res.data });
                }).catch(err => {
                    //dispatch({ type: "UPDATED_STREAM_DATA_ERROR", payload: err });
                });
            }
        })
        // dispatch({ type: "UPDATED_STREAM_DATA", payload: res.data });
    }).catch(err => {
        console.log('Error')
        // dispatch({ type: "UPDATED_STREAM_DATA_ERROR", payload: err });
    });
}
export function patchDeviceSessionData(dispatch, sessionData, device_id) {
    // console.log(sessionData,'before')
    sessionData.map((data, index) => {
        var gps = {}
        var tempNotes = {}
        var values = {}
        if(data.cleared === true){
            if (data.notes === null) {
                tempNotes = {
                    avgValues: {
                        avg1: data.avg1,
                        avg2: data.avg2,
                        max1: data.max1,
                        max2: data.max2
                    },
                    depth: data.depth
                }
                data.notes = tempNotes
                values = {
                    notes: tempNotes
                }
                return axios.patch(`/${localStorage.getItem("organization")}/devices/${device_id}/stream-session/${data.sessionId}`, values).then(res => {
                    // dispatch({ type: "UPDATED_STREAM_DATA", payload: res.data });
                }).catch(err => {
                    // dispatch({ type: "UPDATED_STREAM_DATA_ERROR", payload: err });
                });
            }
        }
    })
}
export function deleteDeviceSession(dispatch, sessionId, device_id) {
    return axios.delete(`/${localStorage.getItem("organization")}/devices/${device_id}/stream-session/${sessionId}`).then(res => {
        //dispatch({ type: "GET_STREAM_DEVICES", payload: res.data.results });
    }).catch(err => {
        console.log('session delete error')
        //dispatch({ type: "GET_STREAM_DEVICES_ERROR", payload: err });
    });
}
export function getDeviceSession1(dispatch, device_id) {
    return axios.get(`/${localStorage.getItem("organization")}/devices/${device_id}/stream-session`).then(res => {
        dispatch({ type: "GET_STREAM_DEVICES", payload: res.data.results });
    }).catch(err => {
        dispatch({ type: "GET_STREAM_DEVICES_ERROR", payload: err });
    });
}
export function getDeviceSessionData(dispatch, session_id, device_id) {
    return axios.get(`/${localStorage.getItem("organization")}/devices/${device_id}/stream-session-data/${session_id}`).then(res => {
        dispatch({ type: "GET_STREAM_DATA", payload: res.data });
    }).catch(err => {
        dispatch({ type: "GET_STREAM_DATA_ERROR", payload: err });
    });
}

export function getDeviceData(dispatch, device_id) {
    return axios.get(`/${localStorage.getItem("organization")}/devices/${device_id}`).then(res => {
        dispatch({ type: "GET_DEVICES", payload: res.data });
    }).catch(err => {
        dispatch({ type: "GET_DEVICES_ERROR", payload: err });
    });
}

export function getGPSData(dispatch, feedDispatch, device_id, dateselected) {
    var startDate = dateselected + ' 00:00:00'
    var endDate = dateselected + ' 23:59:00'

    var utcstartDate = moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss')
    var utcendDate = moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss')

    return axios.get(`/${localStorage.getItem("organization")}/feeds?device__device_id=${device_id}`).then(res => {
        let devicedata = res.data.results;
        if (devicedata) {
            let reqArr = [];
            let reqFeedsArr = [];
            for (let i = 0; i < devicedata.length; i++) {
                if (devicedata[i].category === "gps") {
                    let feedKey = devicedata[i].key;
                    reqArr.push(axios.get(`/${localStorage.getItem("organization")}/feeds/${feedKey}/data?completed_at__range=${utcstartDate}, ${utcendDate}&limit=150`))
                }
                else {
                    let nonGpsFeedKey = devicedata[i].key;
                    reqFeedsArr.push(axios.get(`/${localStorage.getItem("organization")}/feeds/${nonGpsFeedKey}/data?completed_at__range=${utcstartDate}, ${utcendDate}&limit=150`))
                }
            }
            let dataResults = []
            let feeddataResults = []
            axios.all(reqArr).then(axios.spread((...responses) => {
                if (responses && responses[0] && responses[0].data && responses[0].data.results) {
                    dataResults = responses[0].data.results;
                }
                dispatch({ type: "GET_GPS_DATA", payload: dataResults });
            }))
            axios.all(reqFeedsArr).then(axios.spread((...responses) => {

                for (let r = 0; r < responses.length; r++) {
                    if (responses && responses[r] && responses[r].data && responses[r].data.results) {
                        feeddataResults.push(responses[r].data.results);
                    }
                }
                feedDispatch({ type: "GET_FEED_DATA", payload: feeddataResults });
            }))
        }
    }).catch(err => {
        console.log(err);
    });
}

export { DeviceProvider, DeviceContext, useDeviceState };