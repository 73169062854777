import React from "react";
import axios from "axios";

const DashboardContext = React.createContext();
const rootReducer = (state, action) => {
    switch (action.type) {
        case "UPDATE_DEVICES":
            return {
                isLoaded: true,
                devices: action.payload
            };
        case "GET_DEVICES":
            return {
                ...state,
                isLoaded: true,
                devices: action.payload,
                error: ''
            };
        case "GET_DEVICES_ERROR":
            return {
                ...state,
                isLoaded: true,
                devices: null,
                error: action.payload
            };
        case "GET_GPS_DATA":
            return {
                ...state,
                gpsData: action.payload,
                error: ''
            };
    }
}
const DashboardProvider = ({ children }) => {
    const [data, setData] = React.useReducer(rootReducer, {
        isLoaded: false,
        devices: null,
        gpsData: null,
        error: ''
    });
    return (
        <DashboardContext.Provider value={{ data, setData }}>
            {children}
        </DashboardContext.Provider>
    );
};

const useDashboardState = () => {
    const context = React.useContext(DashboardContext);
    return context;
};
export function getDevices(dispatch) {
    return axios.get(`/${localStorage.getItem("organization")}/devices`).then(res => {
        dispatch({ type: "GET_DEVICES", payload: res.data });
    }).catch(err => {
        dispatch({ type: "GET_DEVICES_ERROR", payload: err });
    });
}
export function getGPSData(dispatch) {

    return axios.get(`/${localStorage.getItem("organization")}/devices`).then(res => {
        let devicedata = res.data.results;
        if (devicedata) {
            axios.get(`/${localStorage.getItem("organization")}/devices-metas`).then(resp => {
                let gpsdevices = []
                devicedata.map((device, index) => {
                    resp.data.results.map((resp, index1) => {
                        if (device.device_id === resp.device_id) {
                            //let device = devicedata[r];
                            device.currentDevice = resp.device_id
                            device.lastSeenGps = resp.last_seen_gps
                            device.lastGps = resp.last_gps
                            device.lastRssi = resp.rssi_level
                            device.lastBattery = resp.battery_level
                            device.lastisActive = resp.is_active
                            device.lastStatus = resp.status
                            gpsdevices.push(device);
                        }
                    })
                })
                dispatch({ type: "GET_GPS_DATA", payload: gpsdevices });
            })
        }
    }).catch(err => {
        //console.log(err);
    });
}

export { DashboardProvider, DashboardContext, useDashboardState };