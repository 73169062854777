import * as React from 'react';
import { Component } from 'react';
import { render } from 'react-dom';
import MapGL, { Marker, NavigationControl, WebMercatorViewport } from 'react-map-gl';
import Pins from './pins';

const TOKEN = 'pk.eyJ1IjoiYW5hYmF1YiIsImEiOiJjazgxNndyY2MwM2puM21wdDZldjRqem44In0.qhIfrQkyF5JfLPM-T9Gr5w'; // Set your mapbox token here

const navStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  padding: '10px'
};

export default class PreviewAddMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewport: {
        latitude: 1.3521,
        longitude: 103.8198,
        zoom: 11,
        bearing: 0,
        pitch: 0
      },
      marker: {
        latitude: 1.3521,
        longitude: 103.8198
      },
      events: {}
    };
  }

  _updateViewport = viewport => {
    this.setState({ viewport });
  };

  componentDidMount() {
    if (this.state.data) {
      this.setViewPort();
    }
  }
  setViewPort() {
    let pointsLong = [];
    let pointsLat = [];
    let currentViewState = { latitude: 1.3521, longitude: 103.8198, bearing: 0, pitch: 15, zoom: 11, transitionDuration: 1000 };
    this.setState({ viewport: currentViewState });
    // }
  }

  _logDragEvent(name, event) {
    this.setState({
      events: {
        ...this.state.events,
        [name]: event.lngLat
      }
    });
  }

  _onMarkerDragStart = event => {
    this._logDragEvent('onDragStart', event);
  };

  _onMarkerDrag = event => {
    this._logDragEvent('onDrag', event);
  };

  _onMarkerDragEnd = event => {
    this._logDragEvent('onDragEnd', event);
    this.setState({
      marker: {
        longitude: event.lngLat[0],
        latitude: event.lngLat[1]
      }
    });
    var tempMarker = []
    tempMarker = {
      longitude: event.lngLat[0],
      latitude: event.lngLat[1]
    }
    this.props._onMarkerDragEnd(tempMarker)
  };

  render() {
    const { viewport, marker } = this.state;

    return (
      <MapGL
        {...viewport}
        width="100%"
        height="100%"
        mapStyle="mapbox://styles/sla/cjnci1u153tqz2ro5eka7t0ef"
        onViewportChange={this._updateViewport}
        mapboxApiAccessToken={TOKEN}
      >
        {marker && marker.longitude && marker.latitude &&
          <Marker
            longitude={marker.longitude}
            latitude={marker.latitude}
            offsetTop={-20}
            offsetLeft={-10}
            draggable
            onDragStart={this._onMarkerDragStart}
            onDrag={this._onMarkerDrag}
            onDragEnd={this._onMarkerDragEnd}
          >
            <Pins size={15} />
          </Marker>}

        <div className="nav" style={navStyle}>
          <NavigationControl onViewportChange={this._updateViewport} />
        </div>
      </MapGL>
    );
  }
}
