import React, { useState, useEffect } from "react";
import DeviceMaps from './DeviceMap'
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MaterialTable from "material-table";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import useStyles from "./styles";
import moment from 'moment'
import { useDashboardState, getGPSData, getDevices } from "../../context/DashboardContext";
import {
  SignalCellular1Bar, SignalCellular2Bar, SignalCellular3Bar, SignalCellular4Bar,
  BatteryAlert, Battery20, Battery30, Battery50, Battery60, Battery80, Battery90, BatteryFull, ArrowBack, Power
} from "@material-ui/icons";
import { CircularProgress } from "@material-ui/core";

function Dashboard(props) {

  const classes = useStyles(props);
  const context = useDashboardState();
  const history = useHistory();
  const [showFullPanel, setshowFullPanel] = useState(false);
  const [devices, setdevices] = useState([])
  const [gpsData, setgpsData] = useState([])
  const [filteredgpsData, setfilteredgpsData] = useState([])
  const [isLoading, setisLoading] = useState(true)

  useEffect(() => {
    getGPSData(context.setData);
  }, [context.setData]);

  useEffect(() => {
    if (context.data.gpsData) {
      let gData = context.data.gpsData.map((item, index) => {
        if (item.lastGps && item.lastGps.coordinates && item.lastGps.coordinates[1] && item.lastGps.coordinates[0]) {
          return { geometry: [parseFloat(item.lastGps.coordinates[0]), parseFloat(item.lastGps.coordinates[1])], longitude: parseFloat(item.lastGps.coordinates[0]), latitude: parseFloat(item.lastGps.coordinates[1]), "name": item.device_name, "aliasName": item.alias_name, feed_status: item.feed_status, deviceId: item.device_id, location: item.location, is_active: item.is_active, battery: item.battery_level, lastSeen: item.last_seen }
        }
        else {
          return { geometry: [], longitude: null, latitude: null, "name": "", "aliasName": "", feed_status: "", deviceId: "", location: "", is_active: "", battery: "", lastSeen: "" }
        }
      });
      setgpsData(gData)
      setdevices(context.data.gpsData)
      setisLoading(false)
    }
  }, [context.data.gpsData]);

  useEffect(() => {
    if (gpsData.length > 0) {
      var filteredItems = gpsData.filter(f => f.geometry && f.geometry.length > 0)
      setfilteredgpsData(filteredItems)
    }
  }, [gpsData])

  const showMore = () => {
    setshowFullPanel(!showFullPanel);
  };
  return (
    <>
      {!isLoading && <div style={{ height: '88vh' }}>
        {filteredgpsData && filteredgpsData.length > 0 ?
          <DeviceMaps data={filteredgpsData} history={history} ></DeviceMaps> :
          <div style={{ justifyContent: 'center', textAlign: 'center', fontFamily: 'Karla', fontWeight: "bold", marginTop: '15%' }} > No data to display  </div>}
      </div>}
      {isLoading && <div style={{ height: '88vh', display: 'flex', justifyContent : 'center', alignItems : 'center' }}> <CircularProgress /> </div>}
      {!isLoading && <div className={classes.bottompanel + (!showFullPanel ? "" : " " + classes.fullpanel)} >
        <div style={{ textAlign: "center" }}>
          {!showFullPanel ? (
            <Button
              variant="contained"
              color="default"
              className={classes.morebtn}
              onClick={showMore}
            >
              SEE MORE <ExpandLessIcon></ExpandLessIcon>
            </Button>
          ) : (
              <Button
                variant="contained"
                color="default"
                className={classes.morebtn}
                onClick={showMore}
              >
                SEE LESS <ExpandMoreIcon></ExpandMoreIcon>
              </Button>
            )}
        </div>
        <div style={{ maxWidth: "100%", height: 300 }} className="btmtable">
          <MaterialTable
            title="Devices List"
            columns={[
              { title: "Device Name", field: "device_name" },
              { title: "Alias Name", field: "alias_name" },
              { title: "Location", field: "location" },
              {
                title: "Signal", field: "rssi",
                render: rowData => {
                  let deviceRssiVal = rowData.rssi;
                  return (<>
                    {deviceRssiVal && deviceRssiVal === 0 && <SignalCellular1Bar />}
                    {deviceRssiVal && deviceRssiVal > 0 && deviceRssiVal <= 1 && <SignalCellular1Bar />}
                    {deviceRssiVal && deviceRssiVal > 1 && deviceRssiVal <= 2 && <SignalCellular2Bar />}
                    {deviceRssiVal && deviceRssiVal > 2 && deviceRssiVal <= 3 && <SignalCellular3Bar />}
                    {deviceRssiVal && deviceRssiVal > 3 && deviceRssiVal <= 4 && <SignalCellular4Bar />}
                  </>)
                }
              },
              {
                title: "Battery", field: "battery_level",
                render: rowData => {
                  let deviceBatteryVal = rowData.battery_level;
                  return (<>
                    {deviceBatteryVal === 0 && <BatteryAlert />}
                    {deviceBatteryVal > 0 && deviceBatteryVal <= 20 && <Battery20 />}
                    {deviceBatteryVal > 20 && deviceBatteryVal <= 30 && <Battery30 />}
                    {deviceBatteryVal > 30 && deviceBatteryVal <= 50 && <Battery50 />}
                    {deviceBatteryVal > 50 && deviceBatteryVal <= 60 && <Battery60 />}
                    {deviceBatteryVal > 60 && deviceBatteryVal <= 80 && <Battery80 />}
                    {deviceBatteryVal > 80 && deviceBatteryVal <= 90 && <Battery90 />}
                    {deviceBatteryVal > 90 && deviceBatteryVal <= 100 && <BatteryFull />}
                    {deviceBatteryVal > 100 && <Power />}
                    {deviceBatteryVal < 101 && <span style={{ position: 'relative', top: '-6px' }}>{deviceBatteryVal}%</span>}
                  </>)
                }
              },
              {
                title: "Last Activity", field: "last_seen",
                render: rowData => moment(parseInt(rowData.last_seen) * 1000).fromNow() + ' (' + moment.unix(parseInt(rowData.last_seen)).format('DD-MMM-YYYY, hh:mm a') + ')'
              },
              {
                title: "Device Status", field: "lastisActive", defaultSort: 'desc',
                render: rowData => {
                  return <div style={{ background: (rowData.lastisActive === false) ? "#4A4A4A" : "#00CCCC", borderRadius: "7px", width: '130px' }}>
                    <div style={{ color: "white", marginTop: "5%", textAlign: "center", fontFamily: "'Karla', sans-serif", fontSize: "14px" }} > {(rowData.lastisActive === false) ? 'Session Inactive' : 'Session Active'} </div>
                  </div>
                }
              }
            ]}
            data={devices}
            options={{
              grouping: false,
              paging: false,
              headerStyle: {
                fontSize: "12px",
                fontFamily: "Karla",
                color: "rgb(7, 71, 166)",
                backgroundColor: 'rgb(243 243 243)',
                padding: "4px 12px",
              },
              cellStyle: {
                fontSize: "12px",
                fontFamily: "Karla",
                fontWeight: "bold",
                padding: "4px 12px",
              },
              rowStyle: {
                height: "auto",
              },
              maxBodyHeight: 'calc(100vh - 275px)',
              pageSize: 10,
            }}
            onRowClick={(evt, selectedRow) => {
              history.push({
                pathname: `/app/devices/${selectedRow.device_id}`,
                state: { detail: selectedRow }
              });
            }} />
        </div>
      </div>}
    </>
  )
}

export default Dashboard;
