import * as React from 'react';
import { Component } from 'react';
import { render } from 'react-dom';
import MapGL, {
  Popup,
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl, WebMercatorViewport
} from 'react-map-gl';
import Pins from './pin';
import MarkerInfo from './marker-info';

const TOKEN = 'pk.eyJ1IjoiYW5hYmF1YiIsImEiOiJjazgxNndyY2MwM2puM21wdDZldjRqem44In0.qhIfrQkyF5JfLPM-T9Gr5w'; // Set your mapbox token here

const geolocateStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  padding: '10px'
};

const fullscreenControlStyle = {
  position: 'absolute',
  top: 36,
  left: 0,
  padding: '10px'
};

const navStyle = {
  position: 'absolute',
  top: 72,
  left: 0,
  padding: '10px'
};

const scaleControlStyle = {
  position: 'absolute',
  bottom: 36,
  left: 0,
  padding: '10px'
};
const tooltip = {
  pointerEvents: 'none',
  position: 'absolute',
  minWidth: '266px',
  height: 'auto', //'245px'
  background: 'rgba(33, 49, 49, 0.8)',
  borderRadius: '8px',
  padding: '16px',
  zIndex: 9,
  color: '#fff',
};
const applyToArray = (func, array) => func.apply(Math, array)

const getBoundsForPoints = (cornersLongLat) => {
  const viewport = new WebMercatorViewport({ width: window.innerWidth - 125, height: 225 })
    .fitBounds(cornersLongLat, { paddingLeft: (64 + 20 + 450) }) 
  const { longitude, latitude, zoom } = viewport
  return { longitude, latitude, zoom }
}
const data1 = [
  { time: "25-Jun-2020 03:59 PM - 04:02 PM", param1: '25', param2: "60", param3: "70", param4: "20", depth: "0.3m", latitude: 1.288395, longitude: 103.791439 },
  { time: "25-Jun-2020 03:54 PM - 03:48 PM", param1: '30', param2: "65", param3: "75", param4: "25", depth: "0.3m", latitude: 1.288326, longitude: 103.791112 },
  { time: "25-Jun-2020 03:49 PM - 03:43 PM", param1: '35', param2: "70", param3: "80", param4: "30", depth: "0.3m", latitude: 1.288143, longitude: 103.790645 },
  { time: "25-Jun-2020 03:43 PM - 03:48 PM", param1: '40', param2: "75", param3: "85", param4: "35", depth: "0.3m", latitude: 1.288664, longitude: 103.789942 },
  { time: "25-Jun-2020 03:38 PM - 03:42 PM ", param1: '45', param2: "80", param3: "90", param4: "40", depth: "0.3m", latitude: 1.289227, longitude: 103.790028 }
]

export default class DeviceMaps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewport: {
        latitude: 1.2887,
        longitude: 103.7911,
        zoom: 16,
        bearing: 0,
        pitch: 0
      },
      data: props.data,
      popupInfo: null,
      hoverInfo: null
    };
  }
  componentDidMount() {
    if (this.state.data) {
      this.setViewPort();
    }
  }
  setViewPort() {
    let { data } = this.props;
    let pointsLong = [];
    let pointsLat = [];
    if (data && data.length > 0) {
    
      data.map((point, index)=>{
        if(point.geometry && point.geometry.length > 0 && point.geometry[0] && point.geometry[1]){
          pointsLong.push(point.geometry[0])
          pointsLat.push(point.geometry[1])
        }
      })
      let cornersLongLat = [
        [applyToArray(Math.min, pointsLong), applyToArray(Math.min, pointsLat)],
        [applyToArray(Math.max, pointsLong), applyToArray(Math.max, pointsLat)]
      ]
      const bounds = getBoundsForPoints(cornersLongLat)
      if(bounds && bounds.zoom){
        bounds.zoom = bounds.zoom -1 
      }
      let currentViewState = { ...bounds, bearing: 0, pitch: 15,transitionDuration: 1000 };
      this.setState({ viewport: currentViewState });
    }
  }

  _updateViewport = viewport => {
    this.setState({ viewport });
  };

  _onClickMarker = point => {
    this.setState({ popupInfo: point });
  };

  _onHoverMarker = point => {
    this.setState({ hoverInfo: point });
  };

  _onRemoveMarker = point => {
    this.setState({ hoverInfo: null });
  };

  _renderHoverPopup() {
    const { hoverInfo } = this.state;
    return (
      hoverInfo && (
        <Popup
          tipSize={5}
          anchor="top"
          longitude={hoverInfo.longitude}
          latitude={hoverInfo.latitude}
          closeOnClick={false}
          closeButton={false}
        >
          <MarkerInfo info={hoverInfo} />
        </Popup>
      )
    );
  }
  render() {
    const { viewport, data } = this.state;
    return (
      <MapGL
        {...viewport}
        width="100%"
        height="100%"
        mapStyle="mapbox://styles/sla/cjnci1u153tqz2ro5eka7t0ef"
        onViewportChange={this._updateViewport}
        mapboxApiAccessToken={TOKEN}
      >
        <Pins data={data} onRemove={this._onRemoveMarker} onHover={this._onHoverMarker} onClick={this._onClickMarker} />
        {this._renderHoverPopup()}
        <div style={fullscreenControlStyle}>
          <FullscreenControl />
        </div>
        <div style={navStyle}>
          <NavigationControl />
        </div>
      </MapGL>
    );
  }
}
