import * as React from 'react';
import { PureComponent } from 'react';
import moment from 'moment'

const tooltip = {
  pointerEvents: 'none',
  position: 'relative',
  minWidth: '150px',
  height: 'auto',
  background: 'rgba(33, 49, 49, 0.8)',
  borderRadius: '8px',
  padding: '8px',
  zIndex: 9,
  color: '#fff',
};

export default class MarkerInfo extends PureComponent {
  render() {
    const { info } = this.props;
    const aliasName = `${info.aliasName}`
    const lastSeen = `${info.lastSeen}`
    return (
      <div style={tooltip}>
        <div> {aliasName} </div>
        <div> {lastSeen &&  moment(parseInt(lastSeen) * 1000).fromNow() + ' (' + moment.unix(parseInt(lastSeen)).format('DD-MMM-YYYY, hh:mm a') + ')' } </div>
      </div>
    );
  }
}