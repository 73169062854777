import React from 'react'

function Tile(props) {
    return (
        <>
            {(!props.type || props.type==='default') && <div style={{
                backgroundColor: "rgb(232, 232, 239)",
                borderRadius: '8px',
                display: "flex",
                padding: "8px 12px",
                width : '200px',
                marginRight:'2%',
                ...props.style
            }} className={props.className}>
                <div style={{ flexGrow: 1, fontFamily: "Karla", fontSize: "15px", color: "#426262" }}>
                    <div style={{ fontWeight: 'bold' }}>{props.label}</div>
                    <div>{props.unit}</div>
                </div>
                <div style={{ fontFamily: "Rubik", fontSize: "20px", marginTop:'10%', fontWeight: 'bold' }}>
                    {props.value}
                </div>
            </div>}
            {(props.type && props.type==='outline') && <div style={{
                // backgroundColor: "#C0FFB3",
                borderRadius: '8px',
                border: 'solid 1px',
                borderColor: props.color,
                display: "flex",
                padding: "8px 12px",
                ...props.style
            }} className={props.className}>
                <div style={{ flexGrow: 1, fontFamily: "Karla", fontSize: "15px", color: props.color}}>
                    <div style={{ fontWeight: 'bold' }}>{props.label}</div>
                    <div>{props.unit}</div>
                </div>
                <div style={{ fontFamily: "Rubik", fontSize: "36px", fontWeight: 'bold', color: props.color}}>
                    {props.value}
                </div>
            </div>}
        </>
    )
}

export default Tile
