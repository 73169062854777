import React, { useState, useEffect } from "react";
import { AppBar, Toolbar, IconButton, Tooltip, Menu, Divider } from "@material-ui/core";
import { Menu as MenuIcon, Person as AccountIcon } from "@material-ui/icons";
import classNames from "classnames";
import useStyles from "./styles";
import { Typography } from "../Wrappers/Wrappers";
import axios from "axios";
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
  getDevicesHealth
} from "../../context/LayoutContext";
import { useUserDispatch, signOut } from "../../context/UserContext";

export default function Header(props) {
  var classes = useStyles();
  const [username, setusername] = useState(localStorage.getItem('email'))
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var userDispatch = useUserDispatch();
  var [profileMenu, setProfileMenu] = useState(null);

  axios.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    if (error && error.response && error.response.status === 401) {
      alert("Signature expired");
      signOut(userDispatch, props.history)
    } else {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  });


  useEffect(() => {
    getDevicesHealth(layoutDispatch)
  }, []);


  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonCollapse,
          )}
        >
          {<MenuIcon
                classes={{
                  root: classNames(
                    classes.headerIcon,
                    classes.headerIconCollapse,
                  ),
                }}
              />}
        </IconButton>
        <div style={{ textAlign: 'center', flexGrow: 1, paddingLeft: (layoutState?.devicesHealth) ? 150 : 0 }}>
          <div className={classes.logotype}>
            <div> rEye - Corrosion Monitoring </div>
            <div style={{ fontSize: '12px' }}>Powered by <a style={{ textDecoration: 'none', color: '#fff', fontWeight: 'bold' }} href="https://www.spaceage-labs.com/">SpaceAge Labs</a></div>
          </div>
        </div>
        <div className={classes.grow} />

        {layoutState && layoutState.devicesHealth && <Tooltip title="No. of Active Devices" placement="bottom">
          <div className={classes.deviceCount} style={{ backgroundColor: '#5EDFFF' }}>{layoutState.devicesHealth.active}</div>
        </Tooltip>}
        {layoutState && layoutState.devicesHealth && <Tooltip title="No. of Alert Devices" placement="bottom">
          <div className={classes.deviceCount} style={{ backgroundColor: '#FFBA5A' }}>{layoutState.devicesHealth.alert}</div>
        </Tooltip>}
        {layoutState && layoutState.devicesHealth && <Tooltip title="No. of InActive Devices" placement="bottom">
          <div className={classes.deviceCount} style={{ backgroundColor: '#97B2B2' }}>{layoutState.devicesHealth.inactive}</div>
        </Tooltip>}

        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={e => setProfileMenu(e.currentTarget)}
        >
          <AccountIcon classes={{ root: classes.headerIcon }} />
        </IconButton>

        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h4" weight="medium">
              {username}
            </Typography>
          </div>

          <div className={classes.profileMenuUser}>
            <Typography
              className={classes.profileMenuLink}
              color="primary"
              onClick={() => signOut(userDispatch, props.history)}
            >
              Sign Out
            </Typography>
          </div>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
